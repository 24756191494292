import { rateToPercentString, twentyPercent } from '@orus.eu/amount'
import { z } from 'zod'
import { BaseDimension, type TypeOfDimension } from '../abstract-dimension.js'
import { AmountDimension } from '../amount-dimension.js'
import { BooleanDimension } from '../boolean-dimension.js'
import { ExclusionDimension } from '../exclusion-dimension.js'
import { FinancialRateDimension } from '../financial-rate-dimension.js'
import { NumbersEnumDimension } from '../numbers-enum-dimension.js'
import { StringsDimension } from '../strings-dimension.js'
import { rcphLoiValues, type RcphLoi } from './rcph-loi-dimension.js'

export * from './rcph-cyber-configuration-dimension.js'
export * from './rcph-guarantee-description-text.js'
export * from './rcph-guarantee-description.js'
export * from './rcph-guarantee-names.js'
export * from './rcph-guarantee-text.js'
export * from './rcph-loi-dimension.js'
export * from './rcph-optional-guarantee-children.js'
export * from './rcph-optional-guarantee-description-text.js'
export * from './rcph-optional-guarantee-description.js'
export * from './rcph-options-dimension.js'
export * from './rcph-product-dimension.js'
export * from './rcph-quote-dimension.js'
export * from './rcph-tdmi-loi-dimension.js'
export const rcphVersionDimension = new NumbersEnumDimension({
  name: 'rcphVersion',
  displayValues: { name: 'Version du produit RC Pro Hiscox' },
  values: [1, 2],
} as const)
export type RcphVersion = TypeOfDimension<typeof rcphVersionDimension>

export const rcphSelectedDimension = new BooleanDimension({
  name: 'rcphSelected',
  displayValues: { name: 'RC Pro' },
  tags: ['RCPH'],
} as const)

export const rcphCanadaOrUsaRevenuePercentageDimension = new FinancialRateDimension({
  name: 'rcphCanadaOrUsaRevenuePercentage',
  displayValues: {
    name: 'Pourcentage du chiffre d’affaires',
    hint: "Pourcentage du chiffre d'affaires pour des clients dont le siège social est aux États-Unis ou au Canada",
    placeholder: rateToPercentString(twentyPercent, 0, true),
  },
  tags: ['RCPH'],
} as const)

export const rcphMinLoiDimension = new BaseDimension<'rcphMinLoi', RcphLoi>({
  name: 'rcphMinLoi',
  displayValues: { name: 'LOI Minimum' },
  tags: ['RCPH'],
} as const)
export const rcphMaxLoiDimension = new BaseDimension<'rcphMaxLoi', RcphLoi>({
  name: 'rcphMaxLoi',
  displayValues: { name: 'LOI Maximum' },
  tags: ['RCPH'],
} as const)

/**
 * zod schema compatible with RcphLoi type.
 */
export const rcphLoiZodSchema = z.custom<RcphLoi>(
  (value) => typeof value === 'number' && (rcphLoiValues as readonly number[]).includes(value),
)
/**
 * The amount corresponding to the LOI value. The LOI value itself is a number enum because we can't have indexed types with amounts, and
 * we need them for the configuration of the pricing.
 */
export const rcphLoiAmountDimension = new AmountDimension({
  name: 'rcphLoiAmount',
  displayValues: { name: 'Limite de garanties' },
  tags: ['RCPH'],
} as const)
export const rcphLoiRappelAmountDimension = new AmountDimension({
  name: 'rcphLoiRappelAmount',
  displayValues: { name: 'Limite de garanties (rappel produit)' },
  tags: ['RCPH'],
} as const)

export const rcphDeductibleMaterielDimension = new AmountDimension({
  name: 'rcphDeductibleMateriel',
  displayValues: { name: 'Franchise dommages matériels RCP' },
  tags: ['RCPH'],
} as const)
export const rcphDeductibleImmaterielDimension = new AmountDimension({
  name: 'rcphDeductibleImmateriel',
  displayValues: { name: 'Franchise dommages immatériels RCP' },
  tags: ['RCPH'],
} as const)
export const rcphDeductibleImmaterielProjetDimension = new AmountDimension({
  name: 'rcphDeductibleImmaterielProjet',
  displayValues: { name: 'Franchise coûts de projet' },
  tags: ['RCPH'],
} as const)
export const rcphDeductibleCorporelDimension = new AmountDimension({
  name: 'rcphDeductibleCorporel',
  displayValues: { name: 'Franchise dommages corporels RCP' },
  tags: ['RCPH'],
} as const)
export const rcphDeductibleUsaCanadaDimension = new AmountDimension({
  name: 'rcphDeductibleUsaCanada',
  displayValues: { name: 'Franchise dommages pour USA & Canada' },
  tags: ['RCPH'],
} as const)

export const rcphActiviteMaterielTelecomDimension = new BooleanDimension({
  name: 'rcphActiviteMaterielTelecom',
  displayValues: { name: 'Activité matériel télécom' },
  tags: ['RCPH'],
} as const)
export const rcphActiviteMiseEnRelationDimension = new BooleanDimension({
  name: 'rcphActiviteMiseEnRelation',
  displayValues: { name: 'Activité mise en relation' },
  tags: ['RCPH'],
} as const)
export const rcphOptionRceDimension = new BooleanDimension({
  name: 'rcphOptionRce',
  displayValues: { name: "Option Responsabilité civile d'exploitation" },
  tags: ['RCPH'],
} as const)
export const rcphRceDeductibleDimension = new AmountDimension({
  name: 'rcphRceDeductible',
  displayValues: { name: 'Franchise dommages materiels et immateriels RCE' },
  tags: ['RCPH'],
} as const)

export const rcphRceDeductibleCorporelDimension = new AmountDimension({
  name: 'rcphRceDeductibleCorporel',
  displayValues: { name: 'Franchise dommages corporels RCE' },
  tags: ['RCPH'],
} as const)

export const rcphOptionMondeDimension = new BooleanDimension({
  name: 'rcphOptionMonde',
  displayValues: { name: 'Option US/Canada' },
  tags: ['RCPH'],
} as const)

export const rcphOptionCyberDimension = new BooleanDimension({
  name: 'rcphOptionCyber',
  displayValues: { name: 'Option Cyber' },
  tags: ['RCPH'],
} as const)

export const rcphOptionMrpSmallOfficeDimension = new BooleanDimension({
  name: 'rcphOptionMrpSmallOffice',
  displayValues: { name: 'Option MRP' },
  tags: ['RCPH'],
} as const)

export const rcphOptionTdmiDimension = new BooleanDimension({
  name: 'rcphOptionTdmi',
  displayValues: { name: 'Option TDMI' },
  tags: ['RCPH'],
} as const)
export const rcphOptionTdmiHasMaterialRiskDimension = new BooleanDimension({
  name: 'rcphOptionTdmiHasMaterialRisk',
  displayValues: {
    name: "Le preneur d'assurance représente-t-il un risque matériel ?",
    hint: 'A déjà subi un sinistre touché les biens assurés < 5 ans, assure ses téléphones/smartphone',
  },
  tags: ['RCPH'],
} as const)

/**
 * The amount corresponding to the LOI value. The LOI value itself is a number enum because we can't have indexed types with amounts, and
 * we need them for the configuration of the pricing.
 */
export const rcphEcomTdmiLoiAmountDimension = new AmountDimension({
  name: 'rcphEcomTdmiLoiAmount',
  displayValues: { name: 'Limite de garanties TDMI' },
  tags: ['RCPH'],
} as const)

export const rcphOptionCyberDommagesPeDimension = new BooleanDimension({
  name: 'rcphOptionCyberDommagesPe',
  displayValues: { name: 'Complément - Dommages subis et perte d’exploitation' },
  tags: ['RCPH'],
} as const)
export const rcphOptionCyberDefaillanceFournisseurDimension = new BooleanDimension({
  name: 'rcphOptionCyberDefaillanceFournisseur',
  displayValues: { name: 'Extension "Interruption d’activité — défaillance d’un fournisseur"' },
  tags: ['RCPH'],
} as const)
export const rcphOptionCyberDommagesTiersDimension = new BooleanDimension({
  name: 'rcphOptionCyberDommagesTiers',
  displayValues: { name: 'Complément - Dommages causés à des tiers' },
  tags: ['RCPH'],
} as const)
export const rcphOptionCyberFraudePiratageDimension = new BooleanDimension({
  name: 'rcphOptionCyberFraudePiratage',
  displayValues: { name: 'Complément - Cyberfraude et piratage téléphonique' },
  tags: ['RCPH'],
} as const)
export const rcphOptionCyberHasExcludedActivitiesDimension = new BooleanDimension({
  name: 'rcphOptionCyberHasExcludedActivities',
  displayValues: {
    name: "Le preneur d'assurance exerce-t-il une ou plusieurs des activités exclues ?",
    hint: 'Institutions financières et services financiers, Assurances (agents, courtiers ou tout autre intermédiaire), Agences de notation, Réseaux sociaux, Fournisseur d’utilités (énergie, eau, électricité, gaz, etc.), Parcs d’attractions',
  },
  tags: ['RCPH'],
} as const)
export const rcphCyberHighOnlineRevenueDimension = new BooleanDimension({
  name: 'rcphCyberHighOnlineRevenue',
  displayValues: { name: 'Plus de 25% de revenus en ligne' },
  tags: ['RCPH'],
} as const)
export const rcphCyberManyCreditCardPaymentsDimension = new BooleanDimension({
  name: 'rcphCyberManyCreditCardPayments',
  displayValues: { name: 'Plus de 100 000 paiements CB par an' },
  tags: ['RCPH'],
} as const)
export const rcphOptionCyberHasItRiskDimension = new BooleanDimension({
  name: 'rcphOptionCyberHasItRisk',
  displayValues: {
    name: "Le preneur d'assurance représente-t-il un risque informatique ?",
    hint: "Absence de sauvegarde externe, absence d'antivirus",
  },
  tags: ['RCPH'],
} as const)

export const rcphOptionFreedomAdvantageDimension = new BooleanDimension({
  name: 'rcphOptionFreedomAdvantage',
  displayValues: { name: 'Option "Avantage liberté"' },
  tags: ['RCPH'],
} as const)

export const rcphYearlyBasePremiumDimension = new AmountDimension({
  name: 'rcphYearlyBasePremium',
  displayValues: { name: 'Cotisation annuelle HT' },
  tags: ['RCPH'],
} as const)
export const rcphYearlyTaxesDimension = new AmountDimension({
  name: 'rcphYearlyTaxes',
  displayValues: { name: 'Taxes annuelles' },
  tags: ['RCPH'],
} as const)
export const rcphYearlyTotalPremiumDimension = new AmountDimension({
  name: 'rcphYearlyTotalPremium',
  displayValues: { name: 'Cotisation annuelle TTC' },
  tags: ['RCPH'],
} as const)
export const rcphYearlyPjPremiumDimension = new AmountDimension({
  name: 'rcphYearlyPjPremium',
  displayValues: { name: 'Dont PJ' },
  tags: ['RCPH'],
} as const)

export const rcphExclusionsDimension = new ExclusionDimension({
  name: 'rcphExclusions',
  displayValues: { name: 'Exclusions spécifiques RC Pro Hiscox' },
  tags: ['RCPH'],
} as const)

export const rcphHiscoxActivitiesNamesDimension = new StringsDimension({
  name: 'rcphHiscoxActivitiesNames',
  displayValues: { name: 'Activités HISCOX RC Pro' },
  tags: ['RCPH'],
} as const)

export const rcphActivityDriverDimension = new BooleanDimension({
  name: 'rcphActivityDriver',
  displayValues: { name: 'Clauses supplémentaires Vtc ou taxi applicables' },
  tags: ['RCPH'],
} as const)

export const rcphActivityWellnessDimension = new BooleanDimension({
  name: 'rcphActivityWellness',
  displayValues: { name: 'Clauses supplémentaires Wellness applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityPersonalDimension = new BooleanDimension({
  name: 'rcphActivityPersonal',
  displayValues: { name: 'Clauses supplémentaires Personal applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityRealEstateDimension = new BooleanDimension({
  name: 'rcphActivityRealEstate',
  displayValues: { name: 'Clauses supplémentaires RealEstate applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityVehicleConveyorDimension = new BooleanDimension({
  name: 'rcphActivityVehicleConveyor',
  displayValues: { name: 'Clauses supplémentaires VehicleConveyor applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityWorkBrokerDimension = new BooleanDimension({
  name: 'rcphActivityWorkBroker',
  displayValues: { name: 'Clauses supplémentaires WorkBroker applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityInteriorDecorationDimension = new BooleanDimension({
  name: 'rcphActivityInteriorDecoration',
  displayValues: { name: 'Clauses supplémentaires InteriorDecoration applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityTatooDimension = new BooleanDimension({
  name: 'rcphActivityTatoo',
  displayValues: { name: 'Clauses supplémentaires Tatoo applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityFreightTransportDimension = new BooleanDimension({
  name: 'rcphActivityFreightTransport',
  displayValues: { name: 'Clauses supplémentaires Transport de marchandises de < 3.5 tonnes applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityEventsDimension = new BooleanDimension({
  name: 'rcphActivityEvents',
  displayValues: { name: 'Clauses supplémentaires Events applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityMnaDimension = new BooleanDimension({
  name: 'rcphActivityMna',
  displayValues: { name: 'Clauses supplémentaires Mna applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityTemporaryEmploymentDimension = new BooleanDimension({
  name: 'rcphActivityTemporaryEmployment',
  displayValues: { name: 'Clauses supplémentaires TemporaryEmployment applicables' },
  tags: ['RCPH'],
} as const)
export const rcphActivityEcomDimension = new BooleanDimension({
  name: 'rcphActivityEcom',
  displayValues: { name: 'Clauses e-commerces applicables' },
  tags: ['RCPH'],
} as const)
export const rcphDerogExcluProfReglDimension = new BooleanDimension({
  name: 'rcphDerogExcluProfRegl',
  displayValues: { name: "Dérogation partielle à l'exclusion des profession réglementées" },
  tags: ['RCPH'],
} as const)
export const rcphActivityTravelPlannerDimension = new BooleanDimension({
  name: 'rcphActivityTravelPlanner',
  displayValues: { name: 'Clauses supplémentaires TravelPlanner applicables' },
  tags: ['RCPH'],
} as const)
