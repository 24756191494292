import { CrashContext } from '@orus.eu/pharaoh'
import { memo, useState, type FunctionComponent, type ReactNode } from 'react'

export type CrashHandlerProps = {
  children: ReactNode
}

export const CrashHandler: FunctionComponent<CrashHandlerProps> = memo(function CrashHandler({ children }) {
  const [currentCrashReason, setCurrentCrashReason] = useState<Error | undefined>(undefined)

  if (currentCrashReason) {
    // here we're transgressing the rule of not re-throwing error
    // but we need the error boundary to analyse the type of error to display the correct message
    throw currentCrashReason
  }

  return <CrashContext.Provider value={setCurrentCrashReason}>{children}</CrashContext.Provider>
})
