import { colors } from '../../colors.js'
import { fontweight } from '../../foundation/font-weight-tokens.js'
import type { Skin } from '../skin.js'
import avatarUrl from './orus-face-512.webp'
import { OrusHorizontalLogoImage } from './orus-horizontal-logo-image.js'
import quoteHelpBannerImageUrlEs from './quote-help-banner-image-es.png'
import quoteHelpBannerImageUrl from './quote-help-banner-image.webp'
import { TrustpilotScore } from './trustpilot-score.js'

export const poppinsFontFamily = 'Poppins, sans-serif'

export const interFontFamily = 'Inter'

export const defaultSkin: Skin = {
  avatarUrl,
  avatarBackgroundColor: 'var(--skin-jasmine-dark)',
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrlEs,
  },
  horizontalLogo: {
    aspectRatio: 143 / 44,
    ImageComponent: OrusHorizontalLogoImage,
  },
  showByOrusInHeader: false,
  TrustComponent: TrustpilotScore,
  forcedColors: null,
  helpBlockTextColor: colors.blue[800],
  subscriptionCalloutColors: {
    backgroundColor: colors.periwinkle.dark,
    accentColor: colors.blue[800],
    textColor: colors.blue[800],
  },
  stripe: {
    fontFamily: poppinsFontFamily,
    fontFamilyUrl: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap',
  },
  additionalStylesheets: [],
  cssVariables: {
    '--skin-font': poppinsFontFamily,
    '--skin-font-title': poppinsFontFamily,
    '--skin-font-button': poppinsFontFamily,
    '--skin-font-subtitle1': poppinsFontFamily,
    '--skin-font-subtitle2': poppinsFontFamily,
    '--skin-font-body1': poppinsFontFamily,
    '--skin-font-weight-title': fontweight.medium,
    '--skin-font-weight-subtitle1': fontweight.medium,
    '--skin-font-weight-subtitle2': fontweight.medium,
    '--skin-font-weight-body1': fontweight.regular,
    '--skin-font-weight-body1-medium': fontweight.medium,
    '--skin-font-weight-body1-semibold': fontweight.semibold,
    '--skin-font-weight-body2': fontweight.regular,
    '--skin-font-weight-body2-medium': fontweight.medium,
    '--skin-font-weight-body2-semibold': fontweight.semibold,
    '--skin-font-weight-caption': fontweight.regular,
    '--skin-font-weight-caption-medium': fontweight.medium,
    '--skin-font-weight-button': fontweight.medium,
    '--skin-font-weight-input': fontweight.regular,
    '--skin-blue-100': '#FAFAFF',
    '--skin-blue-200': '#EEEDFF',
    '--skin-blue-300': '#DBD6FF',
    '--skin-blue-400': '#9F94FF',
    '--skin-blue-500': '#4A37F2',
    '--skin-blue-600': '#1F0CC9',
    '--skin-blue-700': '#0D0080',
    '--skin-blue-800': '#070042',
    '--skin-blue-900': '#03001A',
    '--skin-gray-100': '#F1F0F5',
    '--skin-gray-200': '#E6E5ED',
    '--skin-gray-300': '#D9D8E1',
    '--skin-gray-400': '#C0BFC7',
    '--skin-gray-500': '#929199',
    '--skin-gray-600': '#706F76',
    '--skin-gray-700': '#4D4C52',
    '--skin-gray-800': '#35353B',
    '--skin-gray-900': '#18171A',
    '--skin-green-100': '#EDFFF4',
    '--skin-green-200': '#CCF8DE',
    '--skin-green-300': '#91F3B9',
    '--skin-green-400': '#51EC8E',
    '--skin-green-500': '#18D563',
    '--skin-green-600': '#129F4A',
    '--skin-green-700': '#0C6A32',
    '--skin-green-800': '#063519',
    '--skin-green-900': '#02200E',
    '--skin-yellow-100': '#FFFDEE',
    '--skin-yellow-200': '#FFF8CB',
    '--skin-yellow-300': '#FFF195',
    '--skin-yellow-400': '#FFEB62',
    '--skin-yellow-500': '#FFE428',
    '--skin-yellow-600': '#D9BD00',
    '--skin-yellow-700': '#9E8A00',
    '--skin-yellow-800': '#433A00',
    '--skin-yellow-900': '#1D1902',
    '--skin-orange-100': '#FFF8EF',
    '--skin-orange-200': '#FFE9CF',
    '--skin-orange-300': '#FED7AA',
    '--skin-orange-400': '#FEB765',
    '--skin-orange-500': '#FE9925',
    '--skin-orange-600': '#FA8803',
    '--skin-orange-700': '#B76202',
    '--skin-orange-800': '#794202',
    '--skin-orange-900': '#271704',
    '--skin-red-100': '#FFEFF0',
    '--skin-red-200': '#FFDBDD',
    '--skin-red-300': '#FEAAAF',
    '--skin-red-400': '#FE6F78',
    '--skin-red-500': '#FD3541',
    '--skin-red-600': '#DE1925',
    '--skin-red-700': '#A7020C',
    '--skin-red-800': '#590106',
    '--skin-red-900': '#270406',
    '--skin-white': '#FFFFFF',
    '--skin-black': '#000000',
    '--skin-linen': '#FEFEFF',
    '--skin-sky-dark': '#C9F0FC',
    '--skin-sky-light': '#DBF7FF',
    '--skin-sky-gradient': 'radial-gradient(400% 100% at 0% 100%, #C9F0FC 0%, #CDEFFA 100%)',
    '--skin-periwinkle-dark': '#DED1F9',
    '--skin-periwinkle-light': '#F1EBFC',
    '--skin-periwinkle-gradient': 'radial-gradient(400% 100% at 0% 100%, #DED1F9 0%, #E1D8F8 100%)',
    '--skin-mindaro-dark': '#E1F5A8',
    '--skin-mindaro-light': '#EFF9CE',
    '--skin-mindaro-gradient': 'radial-gradient(400% 100% at 0% 100%, #E1F5A8 0%, #E4F5B5 100%)',
    '--skin-jasmine-dark': '#FFECA8',
    '--skin-jasmine-light': '#FFF8DE',
    '--skin-jasmine-gradient': 'radial-gradient(400% 100% at 0% 100%, #FFECA8 0%, #FFF1BE 100%)',
    '--skin-peach-dark': '#FFC4A2',
    '--skin-peach-light': '#FFE6D5',
    '--skin-peach-gradient': 'radial-gradient(400% 100% at 0% 100%, #FFC4A2 0%, #FFD0B4 100%)',
  },
}

export const jsonTreeTheme = {
  scheme: 'Github',
  author: 'Defman21',
  base00: '#ffffff',
  base01: '#f5f5f5',
  base02: '#c8c8fa',
  base03: '#969896',
  base04: '#e8e8e8',
  base05: '#333333',
  base06: '#ffffff',
  base07: '#ffffff',
  base08: '#ed6a43',
  base09: '#0086b3',
  base0A: '#795da3',
  base0B: '#183691',
  base0C: '#183691',
  base0D: '#795da3',
  base0E: '#a71d5d',
  base0F: '#333333',
}
