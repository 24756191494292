import { css } from '@emotion/react'
import { createContext, forwardRef, memo, useContext, useMemo } from 'react'
import { colors } from '../../../colors.js'
import { useSkin } from '../../../skin/hooks.js'
import { Icon, PIXELS_PER_ICON_SIZE, type IconSize } from '../icon/index.js'
import type { CompoundIconName } from '../icon/names.js'

export type AvatarProps = {
  /** The size. If not specified, it'll take all the space available in the container */
  size?: IconSize
  /** If true, the specified size will have priority over the context size */
  overrideContextSize?: boolean
  /** The icon to show. Ignored if `src` is specified */
  icon?: CompoundIconName
  /** The color of the icon. Only applicable with `icon` */
  color?: string
  /** The secondary color of the icon. Only applicable with a duotone `icon` */
  secondaryColor?: string
  /** The image src to show */
  src?: string
  /** The variant of the avatar. Defaults to `full`. `round` is only applicable with `src`  */
  variant?: 'full' | 'contained' | 'round'
  /** The background color, only applicable when `variant` is `contained` */
  containerColor?: string
  imageBackgroundColor?: string
  noTransparency?: boolean
  className?: string
}

export const Avatar = memo(
  forwardRef<HTMLDivElement, AvatarProps>(function Avatar(props, ref) {
    const contextValue = useContext(AvatarContext)
    const skin = useSkin()

    const {
      icon = 'circle-question-solid',
      secondaryColor,
      src,
      variant = 'full',
      className,
      noTransparency = false,
      overrideContextSize = false,
    } = props

    let { size, color, containerColor = colors.blue[100], imageBackgroundColor = 'initial' } = props

    size = overrideContextSize ? (size ?? contextValue.size) : (contextValue.size ?? size)

    color = contextValue.color ?? color

    if (variant === 'contained') {
      color = skin.forcedColors?.color ?? color
      imageBackgroundColor = skin.forcedColors?.backgroundColor ?? imageBackgroundColor
      containerColor = skin.forcedColors?.backgroundColor ?? containerColor
    }

    const cssSize = size ? PIXELS_PER_ICON_SIZE[size] : '100%'
    const shouldBeRound = !!src && variant === 'round'
    const resolvedImageNode = useMemo(() => {
      if (src) {
        return (
          <img
            src={src}
            alt=""
            css={css`
              width: 100%;
              height: 100%;

              background-color: ${imageBackgroundColor};
              border-radius: ${shouldBeRound ? '50%' : '0%'};
            `}
          />
        )
      }

      return (
        <Icon
          icon={icon}
          color={color}
          secondaryColor={secondaryColor}
          noTransparency={noTransparency}
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
          `}
        />
      )
    }, [src, icon, color, secondaryColor, noTransparency, imageBackgroundColor, shouldBeRound])

    return (
      <div
        ref={ref}
        className={className}
        css={css`
          flex-shrink: 0;
          width: ${cssSize};
          height: ${cssSize};
          display: flex;
          justify-content: center;
          align-items: center;
          ${variant === 'contained' ? `background: ${containerColor}; border-radius: 50%;` : ''}
        `}
      >
        <div
          css={css`
            display: flex;

            ${variant === 'contained' ? `width: 50%; height: 50%;` : `width: 100%; height: 100%;`}
          `}
        >
          {resolvedImageNode}
        </div>
      </div>
    )
  }),
)

export const AvatarContext = createContext<Pick<AvatarProps, 'color' | 'size'>>({})
