import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { createLink, useMatch } from '@tanstack/react-router'
import { isValidElement, memo } from 'react'
import type { Permission } from '../../../../right-access-management/src/permission'
import { Row, RowContainer } from '../../components/rows'
import { spacing } from '../../foundation'
import { useTranslate } from '../../localization'
import { isFragment } from '../../util'
import { Dropdown } from '../dropdown'
import { StyledBackofficeNavbar, StyledClientAppNavbar, type NavbarProps } from './common'
import { NavbarAdvisor } from './navbar-advisor'
import { NavbarButton } from './navbar-button'
import { NavbarLine } from './navbar-line'

const DROPDOWN_MIN_WIDTH = '208px'

type NavbarBodyProps = NavbarProps & {
  disconnect: () => void
  permissions: Permission[]
  forceAllPermissions?: boolean
} & (
    | { variant: 'partner'; createSubscription: () => void; organization: string }
    | { variant: 'backoffice'; createSubscription: () => void }
    | {
        variant: 'clientapp'
        displayHealthInsurance: boolean
        displayClaims: boolean
        handleHelp: () => void
        confidentialityLink: string
        cguLink: string
        embeddingPartner?: { name: string; returnToPartner: () => void }
      }
  )

export const NavbarBody = memo<NavbarBodyProps>(function NavbarBody(props: NavbarBodyProps) {
  const { variant, disconnect, permissions, forceAllPermissions = false } = props

  if (variant === 'backoffice') {
    const { createSubscription } = props
    return (
      <BackofficeNavbarBody
        disconnect={disconnect}
        createSubscription={createSubscription}
        permissions={permissions}
        forceAllPermissions={forceAllPermissions}
      />
    )
  }

  if (variant === 'partner') {
    const { createSubscription, organization } = props
    return (
      <PartnerNavbarBody disconnect={disconnect} createSubscription={createSubscription} organization={organization} />
    )
  }

  if (variant === 'clientapp') {
    const { displayHealthInsurance, displayClaims, confidentialityLink, cguLink, embeddingPartner, handleHelp } = props
    return (
      <ClientAppNavbarBody
        disconnect={disconnect}
        displayHealthInsurance={displayHealthInsurance}
        displayClaims={displayClaims}
        handleHelp={handleHelp}
        confidentialityLink={confidentialityLink}
        cguLink={cguLink}
        embeddingPartner={embeddingPartner}
      />
    )
  }

  return
})

const BackofficeNavbarBody = memo(function BackofficeNavbarBody(props: {
  disconnect: () => void
  createSubscription: () => void
  permissions: Permission[]
  forceAllPermissions?: boolean
}) {
  const { permissions, forceAllPermissions = false } = props
  const filteredBackofficeDropdownContent = getBackofficeNavbarBodyDropdownContent(
    permissions,
    forceAllPermissions,
  ).filter((child): child is React.ReactElement => isValidElement(child) && !isFragment(child))

  const isQuotePageActive = useMatch({ from: '/bak/v2-pending-subscriptions/$subscriptionId', shouldThrow: false })

  return (
    <StyledBackofficeNavbar>
      <BackofficeNavbarButtonsGroup>
        <NavbarButton variant="basic" icon="house-regular" title="Dashboard" to="/bak/home" ariaLabel="Dashboard" />
        <NavbarButton
          variant="basic"
          icon="magnifying-glass-regular"
          title="Recherche"
          to="/bak/search"
          ariaLabel="Recherche"
        />

        {permissions.includes('subscription.create') ? (
          <NavbarButton
            variant="basic"
            icon="plus-regular"
            title="Nouveau devis"
            onClick={props.createSubscription}
            forceInactive={!isQuotePageActive}
            ariaLabel="Nouveau devis"
          />
        ) : null}
      </BackofficeNavbarButtonsGroup>

      <BackofficeNavbarButtonsGroup>
        {permissions.includes('subscription.create') ? (
          <NavbarButton
            variant="basic"
            icon="memo-regular"
            title="Devis"
            to="/bak/pending-subscriptions"
            ariaLabel="Devis"
          />
        ) : null}

        <NavbarButton variant="basic" icon="folder-regular" title="Clients" to="/bak/users" ariaLabel="Clients" />

        {permissions.includes('invoices.pay') ? (
          <NavbarButton
            variant="basic"
            icon="landmark-regular"
            title="Facturation"
            to="/bak/admin/invoicing-process-actions"
            ariaLabel="Facturation"
          />
        ) : null}

        {permissions.includes('subscription.changeOwner') ? (
          <NavbarButton
            variant="basic"
            icon="arrows-left-right-regular"
            title="Migration"
            to="/bak/migration"
            ariaLabel="Migration"
          />
        ) : null}

        <NavbarButton
          variant="basic"
          icon="book-regular"
          title="Documentation"
          to="/bak/documentation"
          ariaLabel="Documentation"
        />
      </BackofficeNavbarButtonsGroup>

      <BackofficeNavbarButtonsGroup
        css={css`
          margin-top: auto;
        `}
      >
        {filteredBackofficeDropdownContent.length > 0 ? (
          <Dropdown
            trigger={
              <NavbarButton
                variant="basic"
                icon="ellipsis-regular"
                title="Plus d'options"
                forceInactive
                ariaLabel="Plus d'options"
              />
            }
            placement="right-end"
          >
            <RowContainer
              variant="border"
              size="small"
              css={css`
                min-width: ${DROPDOWN_MIN_WIDTH};
              `}
            >
              {...filteredBackofficeDropdownContent}
            </RowContainer>
          </Dropdown>
        ) : null}

        <NavbarButton
          variant="basic"
          icon="arrow-right-from-bracket-regular"
          title="Se déconnecter"
          onClick={props.disconnect}
          forceInactive
          ariaLabel="Se déconnecter"
        />
      </BackofficeNavbarButtonsGroup>
    </StyledBackofficeNavbar>
  )
})

const PartnerNavbarBody = memo(function PartnerNavbarBody(props: {
  disconnect: () => void
  createSubscription: () => void
  organization: string
}) {
  const { organization } = props

  const isQuotePageActive = useMatch({
    from: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
    shouldThrow: false,
  })

  return (
    <StyledBackofficeNavbar>
      <BackofficeNavbarButtonsGroup>
        <NavbarButton
          variant="basic"
          icon="house-regular"
          title="Dashboard"
          to="/partner/$organization/home"
          params={{ organization }}
          ariaLabel="Dashboard"
        />

        <NavbarButton
          variant="basic"
          icon="magnifying-glass-regular"
          title="Recherche"
          to="/partner/$organization/search"
          params={{ organization }}
          ariaLabel="Recherche"
        />

        <NavbarButton
          variant="basic"
          icon="plus-regular"
          title="Nouveau devis"
          onClick={props.createSubscription}
          forceInactive={!isQuotePageActive}
          ariaLabel="Nouveau devis"
        />
      </BackofficeNavbarButtonsGroup>

      <BackofficeNavbarButtonsGroup>
        <NavbarButton
          variant="basic"
          icon="memo-regular"
          title="Devis"
          to="/partner/$organization/pending-subscriptions"
          params={{ organization }}
          ariaLabel="Devis"
        />
        <NavbarButton
          variant="basic"
          icon="folder-regular"
          title="Clients"
          to="/partner/$organization/users"
          params={{ organization }}
          ariaLabel="Clients"
        />
      </BackofficeNavbarButtonsGroup>

      <BackofficeNavbarButtonsGroup
        css={css`
          margin-top: auto;
        `}
      >
        <NavbarButton
          variant="basic"
          icon="arrow-right-from-bracket-regular"
          title="Se déconnecter"
          onClick={props.disconnect}
          forceInactive
          ariaLabel="Se déconnecter"
        />
      </BackofficeNavbarButtonsGroup>
    </StyledBackofficeNavbar>
  )
})

const RowTanStackLink = createLink(Row)

const getBackofficeNavbarBodyDropdownContent: (
  permissions: Permission[],
  forceAllPermissions: boolean,
) => React.ReactNode[] = (permissions, forceAllPermissions) => [
  import.meta.env.PROD && (permissions.includes('pharaoh.read') || forceAllPermissions) ? (
    <Row
      variant="basic"
      size="small"
      title="Pharaoh"
      titleVariant="body2"
      leftIcon="gem-regular"
      onClick={() => (document.location.href = '/pharaoh/')}
      href="/pharaoh/"
      openInNewTab
      key="1"
    />
  ) : null,
  permissions.includes('activities.read') || forceAllPermissions ? (
    <RowTanStackLink
      variant="basic"
      size="small"
      title="Activités"
      titleVariant="body2"
      leftIcon="list-check-regular"
      to="/bak/activities"
      key="2"
    />
  ) : null,
  permissions.includes('organization.read') || forceAllPermissions ? (
    <RowTanStackLink
      variant="basic"
      size="small"
      title="Organisations"
      titleVariant="body2"
      leftIcon="house-user-regular"
      to="/bak/organizations"
      key="3"
    />
  ) : null,
  permissions.includes('organization.read') || forceAllPermissions ? (
    <RowTanStackLink
      variant="basic"
      size="small"
      title="Adhésions"
      titleVariant="body2"
      leftIcon="square-user-regular"
      to="/bak/memberships"
      key="4"
    />
  ) : null,
  permissions.includes('job.runEphemeral') || forceAllPermissions ? (
    <RowTanStackLink
      variant="basic"
      size="small"
      title="Jobs"
      titleVariant="body2"
      leftIcon="file-lines-regular"
      to="/bak/ephemeral-jobs"
      key="5"
    />
  ) : null,
  permissions.includes('tech.configurations') || forceAllPermissions ? (
    <RowTanStackLink
      variant="basic"
      size="small"
      title="Admin"
      titleVariant="body2"
      leftIcon="gear-regular"
      to="/bak/admin"
      key="6"
    />
  ) : null,
]

const ClientAppNavbarBody = memo(function ClientAppNavbarBody(props: {
  disconnect: () => void
  displayHealthInsurance: boolean
  displayClaims: boolean
  handleHelp: () => void
  confidentialityLink: string
  cguLink: string
  embeddingPartner?: { name: string; returnToPartner: () => void }
}) {
  const {
    disconnect,
    displayHealthInsurance,
    displayClaims,
    handleHelp,
    confidentialityLink,
    cguLink,
    embeddingPartner,
  } = props

  const translate = useTranslate()

  return (
    <StyledClientAppNavbar>
      <ClientAppNavbarButtonsGroup>
        <NavbarLine size="normal" icon="house" title={translate('home')} to="/home" />
        <NavbarLine
          size="normal"
          icon="folder"
          title={translate('my_contracts')}
          to="/contracts"
          data-testid="nav-my-contract"
        />

        {displayHealthInsurance ? (
          <NavbarLine
            size="normal"
            icon="heart"
            title={translate('my_health_insurance')}
            to="/health-insurance"
            data-testid="nav-my-health-insurance"
          />
        ) : null}

        <NavbarLine
          size="normal"
          icon="umbrella-simple"
          title={translate('my_guarantees')}
          to="/contract-guarantees"
          data-testid="nav-my-guarantees"
        />
        <NavbarLine size="normal" icon="receipt" title={translate('my_invoices')} to="/invoices" />
        <NavbarLine
          size="normal"
          icon="credit-card"
          title={translate('my_payment_method')}
          to="/payment-methods/update"
        />

        {displayClaims ? <NavbarLine size="normal" icon="bolt" title={translate('claims')} to="/report-claim" /> : null}

        <NavbarLine size="normal" icon="circle-user" title={translate('my_account')} to="/account" />

        {embeddingPartner ? (
          <NavbarLine
            size="normal"
            icon="arrow-left"
            onClick={embeddingPartner.returnToPartner}
            title={translate('customer_app_return_to_partner', { partnerName: embeddingPartner.name })}
          />
        ) : null}
      </ClientAppNavbarButtonsGroup>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: auto;
          gap: ${spacing[50]};
        `}
      >
        <NavbarAdvisor onClick={handleHelp} />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[20]};
          `}
        >
          <NavbarLine
            size="small"
            title={translate('confidentiality')}
            forcedHref={confidentialityLink}
            forceInactive
          />
          <NavbarLine size="small" title={translate('general_terms')} forcedHref={cguLink} forceInactive />
          <NavbarLine
            size="small"
            title={translate('disconnect')}
            onClick={disconnect}
            forceInactive
            ariaLabel={translate('disconnect')}
          />
        </div>
      </div>
    </StyledClientAppNavbar>
  )
})

const BackofficeNavbarButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`

const ClientAppNavbarButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[20]};
`
