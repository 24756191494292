import { financialRateToNumber, newFinancialRate } from '@orus.eu/amount'
import { PARIS, getStartOfCalendarDate } from '@orus.eu/calendar-date'
import { BaseDimension } from './abstract-dimension.js'
import { ActivitiesDistributionDimension } from './activities-distribution-dimension.js'
import { ActivityDimension } from './activity-dimension.js'
import { AmountDimension } from './amount-dimension.js'
import { BooleanDimension } from './boolean-dimension.js'
import { CalendarDateDimension } from './calendar-date-dimension.js'
import { CommitmentDimension } from './commitment-dimension.js'
import { ActivitiesDimension } from './complementary-activities-dimension.js'
import { CountryDimension } from './country-dimension.js'
import { EmbeddingPartnerDimension } from './embedding-partner-dimension.js'
import { ExclusionDimension } from './exclusion-dimension.js'

import { CompanyIdNumberDimension } from './company-id-number-dimension.js'
import { EmailDimension } from './email-dimension.js'
import type { EsRcphOptionType } from './es-rcph/es-rcph-options-dimension.js'
import { IntegerDimension } from './integer-dimension.js'
import type { MrphOptionType } from './mrph/mrph-options-dimension.js'
import type { MrpwOptionType } from './mrpw/mrpw-options-dimension.js'
import { OptionsListDimension } from './options-list-dimension.js'
import { PaymentRecurrenceDimension } from './payement-recurrence-dimension.js'
import type { RcdaOptionType } from './rcda/rcda-options-dimension.js'
import type { RcphOptionType } from './rcph/rcph-options-dimension.js'
import { SiretDimension } from './siren-dimension.js'
import { StaticFilesDimension } from './static-files-dimension.js'
import { LocalizableTextDimension, TextDimension } from './text-dimension.js'

export const firstNameDimension = new TextDimension({
  name: 'firstName',
  displayKeys: {
    name: 'dimension_name_first_name',
    placeholder: 'dimension_placeholder_first_name',
    ariaLabel: 'dimension_aria_label_first_name',
  },
} as const)

export const lastNameDimension = new TextDimension({
  name: 'lastName',
  displayKeys: {
    name: 'dimension_name_last_name',
    placeholder: 'dimension_placeholder_last_name',
    ariaLabel: 'dimension_aria_label_last_name',
  },
} as const)

export const phoneDimension = new TextDimension({
  name: 'phone',
  displayKeys: {
    name: 'dimension_name_phone',
    placeholder: 'dimension_placeholder_phone',
    ariaLabel: 'dimension_aria_label_phone',
  },
} as const)
export const emailDimension = new EmailDimension({
  name: 'email',
  displayKeys: {
    name: 'dimension_name_email',
    placeholder: 'dimension_placeholder_email',
    ariaLabel: 'dimension_aria_label_email',
  },
} as const)
export const hasAcceptedDimension = new BooleanDimension({
  name: 'hasAccepted',
  displayValues: { name: 'Politique de gestion des données acceptée' },
} as const)
export const hasAcceptedCommercialMessagesDimension = new BooleanDimension({
  name: 'hasAcceptedCommercialMessages',
  displayValues: { name: 'Communication commerciale acceptée' },
} as const)
export const companyNameDimension = new TextDimension({
  name: 'companyName',
  displayKeys: {
    name: 'dimension_name_company_name',
    placeholder: 'dimension_placeholder_company_name',
  },
} as const)
export const companyCreationDateDimension = new CalendarDateDimension({
  name: 'companyCreationDate',
  displayKeys: {
    name: 'dimension_name_company_creation_date',
    hint: 'dimension_hint_company_creation_date',
  },
} as const)
export const companyCreationDateRequiredDimension = new BooleanDimension({
  name: 'companyCreationDateRequired',
  displayValues: { name: "Date de création de l'entité légale requise" },
} as const)
export const companyCreationMaxDateDimension = new CalendarDateDimension({
  name: 'companyCreationMaxDate',
  displayValues: { name: "Date de création de l'entité légale maximum" },
})

export const companyInCreationAllowedDimension = new BooleanDimension({
  name: 'companyInCreationAllowed',
  displayValues: { name: 'Entreprise en cours de création autorisée' },
} as const)
export const companyIdNumberDimension = new CompanyIdNumberDimension({
  name: 'companyIdNumber',
  oldNames: ['sirenData'],
  displayKeys: {
    name: 'dimension_name_company_id_number',
    placeholder: 'dimension_placeholder_company_id_number',
  },
} as const)
export const siretDimension = new SiretDimension({
  name: 'siretData',
  displayValues: {
    name: 'SIRET',
    placeholder: '98765432100000',
  },
} as const)
export const siretRequiredDimension = new BooleanDimension({
  name: 'siretRequired',
  displayValues: { name: 'SIRET requis' },
} as const)

export const paymentRecurrenceDimension = new PaymentRecurrenceDimension({
  name: 'paymentRecurrence',
  displayValues: { name: 'Fréquence de paiement' },
} as const)

export const activityForbiddenMonthlyPaymentDimension = new BooleanDimension({
  name: 'activityForbiddenMonthlyPayment',
  displayValues: { name: 'Paiement mensuel interdit dû à l’activité' },
} as const)

export const forbiddenMonthlyPaymentExemptionDimension = new BooleanDimension({
  name: 'forbiddenMonthlyPaymentExemption',
  displayValues: { name: 'Dérogation au paiement mensuel interdit' },
} as const)

export const forbiddenMonthlyPaymentDimension = new BooleanDimension({
  name: 'forbiddenMonthlyPayment',
  displayValues: { name: 'Paiement mensuel interdit' },
} as const)

export const isEstimatedRevenueRequiredDimension = new BooleanDimension({
  name: 'isEstimatedRevenueRequired',
  displayValues: { name: 'Revenu estimé requis' },
} as const)

export const birthDateDimension = new CalendarDateDimension({
  name: 'birthDate',
  displayValues: { name: 'Date de naissance' },
} as const)

export const birthCountryDimension = new CountryDimension({
  name: 'birthCountry',
  displayValues: {
    name: 'Pays de naissance',
    ariaLabel: 'Pays de naissance',
  },
} as const)

export const birthCityDimension = new TextDimension({
  name: 'birthCity',
  displayValues: { name: 'Ville de naissance' },
} as const)

export const workforceDimension = new IntegerDimension({
  name: 'workforce',
  displayValues: {
    name: 'Effectif total',
    hint: 'L’ensemble des salariés, les apprentis et le dirigeant. Ne sont pas compris les stagiaires, intérimaires et les conjoints non-employés',
    placeholder: '1',
  },
  tags: ['RCDA'],
})

/**
 * To have a more readable offer, we try to have as many products as possible (but not all products yet
 * as of 09/07/2023) offering the same discount (~5%) for a yearly commitment.
 * To be accurate, because how the hiscox pricing works, it's not a 5% discount for yearly commitement,
 * but a 5% penalty for monthly commitment, which is the equivalent of a ~4.76% discount for yearly
 * commitement, but it's close enought to simplify the message and talk about a "5%"" discount in the UI.
 */
const globalMonthlyCommitmentPenaltyFivePercentRate = newFinancialRate('0.05')
export const changeMonthlyCommitmentPenaltyTimestamp = getStartOfCalendarDate(
  { year: 2024, oneBasedMonth: 1, oneBasedDay: 1 },
  PARIS,
).toMillis()

export const globalMonthlyCommitmentPenaltyTenPercentRate = newFinancialRate('0.10')

export const globalMonthlyCommitmentFivePercentRateMultiplier =
  financialRateToNumber(globalMonthlyCommitmentPenaltyFivePercentRate) + 1
export const globalMonthlyCommitmentTenPercentRateMultiplier =
  financialRateToNumber(globalMonthlyCommitmentPenaltyTenPercentRate) + 1

export const yearlyCommitmentHasDiscountDimension = new BooleanDimension({
  name: 'yearlyCommitmentHasDiscount',
  displayValues: { name: "Tous les produits choisis offrent une réduction de 5% pour l'engagement annuel" },
} as const)

export const commitmentDimension = new CommitmentDimension({
  name: 'commitment',
  displayValues: { name: "Durée de l'engagement" },
} as const)

export const activityDimension = new ActivityDimension({
  name: 'activity',
  displayValues: { name: 'Activité' },
} as const)

export const complementaryActivitiesDimension = new ActivitiesDimension({
  name: 'complementaryActivities',
  displayValues: { name: 'Activités complémentaires' },
} as const)

export const activitiesDimension = new ActivitiesDimension({
  name: 'activities',
  displayValues: { name: 'Activités (principale et complémentaires)' },
} as const)

export const hasMultipleActivitiesDimension = new BooleanDimension({
  name: 'hasMultipleActivities',
  displayValues: { name: 'A plusieurs activités' },
})

export const maxActivitiesCountDimension = new IntegerDimension({
  name: 'maxActivitiesCount',
  displayValues: { name: "Nombre maximum d'activités" },
} as const)

export const activitiesDistributionDimension = new ActivitiesDistributionDimension({
  name: 'activitiesDistribution',
  displayValues: {
    name: 'Répartition des activités',
    hint: 'Répartition approximative de votre chiffre d’affaires par activité sur la prochaine année',
  },
} as const)

export const activitiesDistributionTextDimension = new TextDimension({
  name: 'activitiesDistributionText',
  displayValues: { name: 'Répartition des activités (représentation textuelle)' },
} as const)

export const startDateDimension = new CalendarDateDimension({
  name: 'startDate',
  displayKeys: {
    name: 'dimension_name_start_date',
  },
} as const)
export const endDateDimension = new CalendarDateDimension({
  name: 'endDate',
  displayValues: { name: 'Date de fin du contrat' },
} as const)

export const estimatedRevenueDimension = new AmountDimension({
  name: 'estimatedRevenue',
  displayKeys: {
    name: 'dimension_name_estimated_revenue',
    hint: 'dimension_hint_estimated_revenue',
    placeholder: 'dimension_placeholder_estimated_revenue',
  },
} as const)

export const terminatePreviousContractDimension = new BooleanDimension({
  name: 'terminatePreviousContract',
  displayValues: { name: "Résiliation de l'ancien contrat" },
} as const)

export const exclusionsDimension = new ExclusionDimension({
  name: 'exclusions',
  displayValues: { name: 'Exclusions' },
} as const)

/**
 * Used by RC Pro and MRP products in combination with hiscoxActivityNamesDimension to include a text like that
 * in the agreed terms
 *
 * > L’assuré déclare exercer la profession suivante : [[ORUS_ACTIVITY_GROUP_NAME]].
 * > Ce contrat d'assurance le couvre donc pour les activités suivantes : [[HISCOX_ACTIVITY_NAMES]].
 */
export const orusActivityGroupNameDimension = new TextDimension({
  name: 'orusActivitiesGroupNames',
  displayValues: {
    name: 'Activité (orus)',
    hint: "Nom lisible de l'activité dans le référéntiel Orus",
  },
} as const)

export const orusActivitiesGroupNamesDimension = new TextDimension({
  name: 'orusActivityGroupName',
  displayValues: {
    name: 'Activités (Orus)',
    hint: 'Noms lisibles des activités dans le référéntiel Orus',
  },
} as const)

/**
 * See orusActivityGroupNameDimension
 */
export const hiscoxActivityNamesDimension = new TextDimension({
  name: 'hiscoxActivityNames',
  displayValues: {
    name: 'Activités autorisées',
    hint: "Liste d'activités compatibles dans le référentiel Hiscox, séparées par des virgules",
  },
} as const)

export type OptionType = MrphOptionType | RcphOptionType | MrpwOptionType | RcdaOptionType | EsRcphOptionType
export const optionsDimension = new OptionsListDimension<'allOptions', OptionType>({
  name: 'allOptions',
  displayValues: { name: 'Options from all products' },
} as const)

export const generalTermsDimension = new StaticFilesDimension({
  name: 'generalTerms',
  displayValues: { name: 'General terms' },
} as const)

export const hiscoxIsRiskCarrierDimension = new BooleanDimension({
  name: 'hiscoxIsRiskCarrier',
  displayValues: { name: 'Hiscox is one of the risk carriers' },
} as const)

export const wakamIsRiskCarrierDimension = new BooleanDimension({
  name: 'wakamIsRiskCarrier',
  displayValues: { name: 'Wakam is one of the risk carriers' },
} as const)

export const placeNameDimension = new TextDimension({
  name: 'placeName',
  displayValues: { name: "Nom de l'établissement" },
} as const)

export const customCoverDimension = new TextDimension({
  name: 'customCover',
  displayValues: {
    name: 'Page de couverture',
    hint: 'Ce texte s\'affiche comme un paragraphe à la fin de la section "Votre souscription" au début des CPs',
  },
} as const)

export const customSignatureDimension = new TextDimension({
  name: 'customSignature',
  displayValues: {
    name: 'Page de signature',
    hint: "Ce texte s'affiche comme un paragraphe au dessus de l'encadré gris de la page de signature à la fin des CPs",
  },
} as const)

export const customRcphActivityDimension = new TextDimension({
  name: 'customRcphActivity',
  displayValues: {
    name: 'Activité (Rc Pro Hiscox)',
    hint: 'Ce texte s\'affiche à la suite de la liste d\'activités dans la section "Votre activité" au début des CPs ',
  },
} as const)

export const customRcphSummaryDimension = new TextDimension({
  name: 'customRcphSummary',
  displayValues: {
    name: 'Résumé (Rc Pro Hiscox)',
    hint: 'Ce texte s\'affiche comme un paragraphe à la fin de la section "Votre police d\'assurance" au début des CPs',
  },
} as const)

export const customMrpActivityDimension = new TextDimension({
  name: 'customMrpActivity',
  displayValues: {
    name: 'Activité (Mrph ou Mrpw)',
    hint: 'Ce texte s\'affiche à la suite de la liste d\'activités dans la section "Votre activité" des CPs',
  },
} as const)

export const customMrpSummaryDimension = new TextDimension({
  name: 'customMrpSummary',
  displayValues: {
    name: 'Résumé (Mrph ou Mrpw)',
    hint: 'Ce texte s\'affiche comme un paragraphe à la fin de la section "Votre police dassurance" des CPs',
  },
} as const)

export const customMrpActivityAddressDimension = new TextDimension({
  name: 'customMrpActivityAddress',
  displayValues: {
    name: 'Adresse du local (Mprh ou Mrpw)',
    hint: 'Ce texte s\'affiche comme un paragraphe en dessous de la ligne "Adresse du local assuré" des CPs',
  },
} as const)

export const customMutaSummaryDimension = new TextDimension({
  name: 'customMutaSummary',
  displayValues: {
    name: 'Résumé (Mutuelle Acheel)',
    hint: 'Ce texte s\'affiche comme un paragraphe à la fin de la section "Votre complémentaire santé “Indépendants”" au début des CPs',
  },
} as const)

export const customRcdaActivityDimension = new TextDimension({
  name: 'customRcdaActivity',
  displayValues: {
    name: 'Activité (Décennale Axeria)',
    hint: 'Ce texte s\'affiche à la suite de la liste d\'activités dans la section "Vos informations" des CPs',
  },
} as const)

export const customRcdaSummaryDimension = new TextDimension({
  name: 'customRcdaSummary',
  displayValues: {
    name: 'Résumé (Décennale Axeria)',
    hint: 'Ce texte s\'affiche comme un paragraphe à la fin de la section "Votre police d’assurance RC Décennale des Entreprises de Construction" au début des CPs',
  },
} as const)

/**
 * The "riskCarrierProduct" concept is one level below the "product" concept (mrph, mrpw, rcph etc.).
 * A customer can have several risk carrier products as rcp and mrp can be selected in the same time for example.
 * rcph-xxx-v1 is for rcph risk carrier products before mrp-and-rc-pro (no tag nor secu at this time).
 * mrpw-rest-v1 is for restaurant legacy.
 * See https://www.notion.so/orus-team/Technical-concepts-as-of-00ffc0d93c924f21b894a0a8407ed801 for more details.
 */
export const riskCarrierProductsTypeNames = [
  'rcph-tech-v1',
  'rcph-mac-v1',
  'rcph-man-v1',
  'rcph-media-v1',
  'rcph-misc-v1',
  'rcph-tech-v2',
  'rcph-mac-v2',
  'rcph-man-v2',
  'rcph-media-v2',
  'rcph-misc-v2',
  'rcph-tag-v2',
  'rcph-secu-v2',
  'rcph-ecom-v2',
  'mrpw-rest-v1',
  'mrpw-rest-v2',
  'mrpw-retail-v1',
  'mrph-office-v1',
  'muta-inde-v1',
  'rcda-btp-v1',
  'es-rcph-mac-v1',
  'es-rcph-man-v1',
  'es-rcph-media-v1',
  'es-rcph-misc-v1',
  'es-rcph-tech-v1',
  'es-rcph-tag-v1',
  'es-rcph-secu-v1',
  'es-rcph-gest-v1',
  'es-rcph-eco-v1',
  'es-rcph-eno-v1',
  'es-rcph-etg-v1',
] as const

export type RiskCarrierProductType = (typeof riskCarrierProductsTypeNames)[number]

export const riskCarrierProductsDimension = new BaseDimension<'riskCarrierProducts', RiskCarrierProductType[]>({
  name: 'riskCarrierProducts',
  displayValues: { name: 'Risk Carrier Product(s)' },
} as const)

export const minStartDateDimension = new CalendarDateDimension({
  name: 'minStartDate',
  displayValues: { name: 'Date de débug du contrat minimum' },
} as const)

export const maxStartDateDimension = new CalendarDateDimension({
  name: 'maxStartDate',
  displayValues: { name: 'Date de début du contrat différée maximale' },
} as const)

export const maxStartDateExplanationDimension = new TextDimension({
  name: 'maxStartDateExplanation',
  displayValues: { name: 'Explication du maximum de la date de début du contrat différée' },
} as const)

export type StatementCard = {
  emoji: string
  title: string
  content: string[]
  info?: string
  banner?: boolean
  type?: 'info' | 'warning'
}

/**
 * The statement declared by the user, as a series of markdown texts
 */
export const statementDimension = new BaseDimension<'statement', StatementCard[]>({
  name: 'statement',
  displayValues: { name: "Declaration sur l'honneur" },
} as const)

/**
 * The statement warning displayed to the user
 */
export const statementWarningDimension = new LocalizableTextDimension({
  name: 'statementWarning',
  displayValues: { name: 'Avertissement de la déclaration sur l’honneur' },
} as const)

/**
 * Used during subscription to give an estimate of rht earliers payment date
 */
export const expectedFirstPaymentDateDimension = new CalendarDateDimension({
  name: 'expectedFirstPaymentDate',
  displayValues: { name: 'Date du premier paiement' },
} as const)

export const lockedDimension = new BooleanDimension({
  name: 'locked',
  displayValues: { name: 'Verrouillée' },
})

/**
 * An embedding parner is a thrid party company that embeds our subscription process in their own subscription
 * process. Based on the value, various aspects of the subscription process and the onboarded experience can be customized.
 * Nominal use-case : The embedding partner gives their process a call to action with a link to orus with the embeddingPartner query param
 * set to their name.
 */
export const embeddingPartnerDimension = new EmbeddingPartnerDimension({
  name: 'embeddingPartner',
  displayValues: { name: 'Partenaire d’intégration' },
} as const)

export const organizationDimension = new TextDimension({
  name: 'organization',
  displayValues: {
    name: 'Organisation',
    placeholder: 'Orus',
    ariaLabel: 'Organisation à laquelle appartient la souscription',
  },
} as const)

export const globalDocumentNoteDimension = new TextDimension({
  name: 'globalDocumentNote',
  displayValues: {
    name: 'Note globale du dossier',
    placeholder: 'Dossier de souscription en plomberie OK.',
    ariaLabel: 'Note globale du dossier',
  },
} as const)

export const organizationCanTerminateContractsDimension = new BooleanDimension({
  name: 'organizationCanTerminateContracts',
  displayValues: { name: "L'organisation a la possibilité de résilier des contrats" },
} as const)
