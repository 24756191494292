import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'
import { TechnicalError, ensureError } from '@orus.eu/error'
import { isPharaohSkin, useCrash, usePharaohSkin, useSetSkin, type Skin } from '@orus.eu/pharaoh'
import { failure, isFailure, success, type Result } from '@orus.eu/result'
import { memo, useEffect, useMemo, useState, type ReactNode } from 'react'
import { trpc } from '../../client'
import { EmbeddingPartnerProvider } from '../../lib/embedding-partner'
import { useSession } from '../../lib/session'

export const EmbeddingPartnerWrapper = memo<{ children: ReactNode }>(function EmbeddingPartnerWrapper({ children }) {
  const session = useSession()
  const crash = useCrash()
  const [embeddingPartnerId, setEmbeddingPartnerId] = useState<string | undefined>(getInitialEmbeddingPartnerId())
  const setSkin = useSetSkin()
  const { setSkin: setPharaohSkin } = usePharaohSkin()

  // This effects reacts to session changes to check if an embedding partner needs
  // to be applied, based on the customer's contract
  useEffect(() => {
    if (session?.permissions.type !== 'client') return
    if (!session.permissions.rolesPermissions.flatMap((r) => r.permissions).includes('contracts.read')) return

    let cancelled = false

    loadContracts().then(
      (result) => {
        if (cancelled) return

        if (isFailure(result)) {
          switch (result.problem) {
            case 'loading-failure':
              // in case of network failure, crash the page so the user can retry, without creating a sentry report
              crash(new TechnicalError('Loading failure'))
              return
          }
        }

        const contracts = result.output

        if (contracts.length < 1) {
          // No contract => no embedding partner
          setEmbeddingPartnerId(undefined)
          return
        }

        // Use embedding partner of the first contract
        const firstContract = contracts[0]
        const embeddingPartner = firstContract.embeddingPartner
        setEmbeddingPartnerId(embeddingPartner === 'none' ? undefined : embeddingPartner.id)
      },
      (err) => {
        if (cancelled) return

        // in case of unexpected error, crash the page but create a sentry report
        crash(ensureError(err))
        throw new TechnicalError(err)
      },
    )

    return () => {
      cancelled = true
    }
  }, [crash, session, setEmbeddingPartnerId])

  // This effect reacts to embeddingPartner changes to update the skin accordingly
  useEffect(() => {
    if (!embeddingPartnerId) {
      setSkin(null)
      setPharaohSkin('light')
      return
    }

    if (isPharaohSkin(embeddingPartnerId)) setPharaohSkin(embeddingPartnerId)

    let cancelled = false

    skinFactories[embeddingPartnerId]()
      .then((skin) => {
        if (cancelled) {
          return
        }

        setSkin(skin)
      })
      .catch((err) => {
        if (cancelled) {
          return
        }

        console.error(err)
      })

    return () => {
      cancelled = true
    }
  }, [embeddingPartnerId, setSkin, setPharaohSkin])

  const embeddingPartnerContextValue = useMemo(
    () => ({ embeddingPartnerId, setEmbeddingPartnerId }),
    [embeddingPartnerId, setEmbeddingPartnerId],
  )

  return <EmbeddingPartnerProvider value={embeddingPartnerContextValue}>{children}</EmbeddingPartnerProvider>
})

async function loadContracts(): Promise<Result<CustomerContractDescription[], 'loading-failure'>> {
  let contracts: CustomerContractDescription[]
  try {
    contracts = await trpc.contracts.listMyContracts.query()
  } catch (_err) {
    return failure('loading-failure')
  }
  return success(contracts)
}

function getInitialEmbeddingPartnerId(): string | undefined {
  const url = new URL(document.location.href)

  const embeddingPartnerParameter = url.searchParams.get('embeddingPartner')
  if (embeddingPartnerParameter && embeddingPartnerParameter in skinFactories) {
    return embeddingPartnerParameter
  }

  const organizationParameter = url.searchParams.get('organization')
  if (organizationParameter && organizationParameter in skinFactories) {
    return organizationParameter
  }

  return undefined
}

const skinFactories: Record<string, () => Promise<Skin>> = {
  sidecare: () => import('../../lib/skins/sidecare').then((m) => m.sidecareSkin),
  superindep: () => import('../../lib/skins/superindep').then((m) => m.superindepSkin),
  propulse: () => import('../../lib/skins/propulse').then((m) => m.propulseSkin),
  jump: () => import('../../lib/skins/jump').then((m) => m.jumpSkin),
  shine: () => import('../../lib/skins/shine').then((m) => m.shineSkin),
  legalstart: () => import('../../lib/skins/legalstart').then((m) => m.legalstartSkin),
}
