import styled from '@emotion/styled'
import { Autocomplete, InputAdornment, Popper } from '@mui/material'
import { isValidElement, memo, useState, type ChangeEvent, type KeyboardEvent } from 'react'
import { borderStroke, colorTokens, shadow, spacing } from '../../../foundation'
import { useScreenVariant, type ScreenVariant } from '../../../hooks/use-screen-variant'
import { Icon } from '../../atoms'
import { cssPropsPerScreenVariantPerTextVariant, type TextCssProps } from '../../atoms/text'
import { TextField } from '../text-field/text-field'

type SearchBarOption = {
  id: string
  label: string
}

type SearchBarSize = 'small' | 'large'

type SearchBarProps = {
  options: SearchBarOption[]
  size: SearchBarSize
  variant?: ScreenVariant
  placeholder?: string
  disabled?: boolean
  /**
   * Allows the user to enter a value that is not present in the list of options
   */
  allowCustomValue?: boolean
  noOptionsText?: string
  fullWidth?: boolean
  /**
   * Temporary prop to avoid including the Popper in the app right away
   */
  hasPopper?: boolean

  inputValue?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: KeyboardEvent) => void
  error?: boolean
}

type PopperProps = {
  size: SearchBarSize
  typography: TextCssProps
}

export const SearchBar = memo<SearchBarProps>(function SearchBar(props) {
  const {
    options,
    size,
    variant,
    placeholder,
    disabled = false,
    allowCustomValue = false,
    noOptionsText,
    fullWidth = false,
    hasPopper = false,
    inputValue,
    ...inputProps
  } = props

  const screenVariant = useScreenVariant()
  const [value, setValue] = useState(inputValue)

  const typography = cssPropsPerScreenVariantPerTextVariant['body2'][variant ?? screenVariant]

  // Should be removed when the hasPopper prop is retired
  let extraProps = {}
  if (!hasPopper) {
    extraProps = { open: false }
  }

  return (
    <StyledSearchBar
      disablePortal
      forcePopupIcon={false}
      autoHighlight={true}
      options={options}
      disabled={disabled}
      freeSolo={allowCustomValue}
      fullWidth={fullWidth}
      noOptionsText={noOptionsText}
      inputValue={value}
      onInputChange={(_event, newInputValue) => {
        setValue(newInputValue)
      }}
      {...extraProps}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          size={size}
          placeholder={placeholder}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  icon="magnifying-glass-regular"
                  size="30"
                  color={disabled ? colorTokens['color-fg-base-disable'] : ''}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      PopperComponent={(popperComponentProps) => {
        const hasOptions = () => {
          return (
            isValidElement(popperComponentProps.children) &&
            isValidElement(popperComponentProps.children.props?.children) &&
            Array.isArray(popperComponentProps.children.props.children.props?.children)
          )
        }
        const isHidden = !noOptionsText && !hasOptions()

        return (
          <StyledPopper
            {...popperComponentProps}
            size={size}
            typography={typography}
            // Custom logic when no options are found:
            // - By default, the Popper is hidden
            // - If there is a noOptionsText, the Popper is displayed
            sx={{
              ...(isHidden && { display: 'none' }),
            }}
          />
        )
      }}
    />
  )
})

const StyledSearchBar = styled(Autocomplete)`
  & .MuiOutlinedInput-root,
  & .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0 ${spacing[40]};
    box-shadow: ${shadow.bottom['05']};
  }

  & .MuiOutlinedInput-root .MuiAutocomplete-input,
  & .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    padding-left: ${spacing[30]};
  }

  & .MuiAutocomplete-clearIndicator {
    visibility: visible;

    &:hover {
      background: none;
    }
  }

  & .MuiInputAdornment-root {
    margin-right: 0;
  }

  & .MuiAutocomplete-inputRoot .MuiInputBase-input {
    box-shadow: none;
  }
`
const StyledPopper = styled(Popper)<PopperProps>`
  & .MuiAutocomplete-paper {
    margin-top: ${spacing['30']};
    border-radius: ${spacing['30']};
    border: ${borderStroke['20']} solid ${colorTokens['color-stroke-base']};
    box-shadow: ${shadow.bottom[30]};
  }

  & .MuiAutocomplete-listbox {
    padding: 0;
  }

  & .MuiAutocomplete-option {
    padding: ${({ size }) => (size === 'small' ? `${spacing['30']} ${spacing['50']}` : spacing['50'])};
    ${({ typography }) => typography};
  }
`
