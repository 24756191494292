import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { createLink, Link } from '@tanstack/react-router'
import { memo, type MouseEvent, type ReactNode } from 'react'
import { colors } from '../../../colors.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { mobileMediaQuery } from '../../../hooks/index.js'
import { primaryColor } from '../../../theme.js'
import { Avatar } from '../../atoms/avatar/avatar.js'
import { Text } from '../../atoms/text/text.js'

export const RowContainerV2 = styled.div<{ variant?: 'mobile' | 'desktop'; noContainedBorder?: boolean }>`
  ${({ noContainedBorder }) =>
    noContainedBorder === true
      ? `
        `
      : `
        border: 1px solid ${colors.gray[200]};
        border-radius: 8px;
        `}

  display: flex;
  flex-direction: column;
  background-color: ${colors.white};

  /* prevent hover effect on first and last lines to overflow outside the rounded corner */
  overflow: hidden;

  ${({ variant }) =>
    variant === 'mobile'
      ? `
      ${mobileMediaQuery} {
        border-top: 1px solid ${colors.gray[200]};
        border-bottom: 1px solid ${colors.gray[200]};
        border-radius: 0px;
        padding-left: ${spacing[60]};
        padding-right: ${spacing[60]};
        display: flex;
        flex-direction: column;
        background-color: ${colors.white};
        /* prevent hover effect on first and last lines to overflow outside the rounded corner */
        overflow: hidden;
      }
        `
      : ``}
`

type RowButtonV2Props = {
  primaryText?: string
  secondaryText?: string
  tertiaryText?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  value?: string
  avatarLeft?: ReactNode
  avatarRight?: ReactNode
  isSelected?: boolean
}

export const RowButtonV2 = memo<RowButtonV2Props>(function RowButtonV2(props) {
  const {
    primaryText,
    secondaryText,
    tertiaryText,
    onClick,
    value,
    avatarLeft,
    avatarRight,
    isSelected = false,
  } = props

  const content = (
    <div
      css={css`
        display: grid;
        align-items: center;
        gap: ${spacing[50]};
        color: ${primaryColor};
        grid-template-columns: ${(avatarLeft ? 'min-content' : '') +
        ' auto ' +
        (avatarRight ? 'min-content ' : '') +
        'min-content'};
      `}
    >
      {avatarLeft ?? undefined}
      <span
        css={css`
          align-items: center;
        `}
      >
        {primaryText ? (
          <Text className="mostImportantText" variant="body2Medium">
            {primaryText}
          </Text>
        ) : (
          <></>
        )}
        {secondaryText ? (
          <Text className={!primaryText ? 'mostImportantText' : undefined} variant="body2">
            {secondaryText}
          </Text>
        ) : (
          <></>
        )}
        {tertiaryText ? (
          <Text className={!primaryText && !secondaryText ? 'mostImportantText' : undefined} variant="body2">
            {tertiaryText}
          </Text>
        ) : (
          <></>
        )}
      </span>
      {avatarRight ?? undefined}
      <Avatar icon="angle-right-regular" size="30" />
    </div>
  )

  const cssToApply = css`
    text-decoration: none;
    ${BASE_ROW_CSS};
    cursor: pointer;
    background-color: ${isSelected ? colors.blue[200] : colors.white};

    &:hover {
      background-color: ${colors.blue[100]};
    }
  `

  return 'href' in props ? (
    <Link to={props.href} css={cssToApply} key={value}>
      {content}
    </Link>
  ) : (
    <button type="button" onClick={onClick} value={value} css={cssToApply} key={value}>
      {content}
    </button>
  )
})

export const RowButtonLinkV2 = createLink(RowButtonV2)

export type RowStaticV2Props = {
  label: string
  rightNode: ReactNode
}

export const RowStaticV2 = memo<RowStaticV2Props>(function RowStaticV2({ label, rightNode }) {
  return (
    <div
      css={css`
        ${BASE_ROW_CSS};

        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <Text variant="body2Medium">{label}</Text>
      <div>{rightNode}</div>
    </div>
  )
})

const BASE_ROW_CSS = css`
  text-align: left;
  border: none;
  background: none;
  padding: ${spacing[40]} ${spacing[50]} ${spacing[40]} ${spacing[50]};

  :not(:first-child) {
    border-top: 1px solid ${colors.gray[200]};
  }
`
