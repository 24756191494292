export type ErrorOptions = {
  /**
   * The original cause of the error.
   *
   * It is used when catching and re-throwing an error with a more-specific
   * or useful error message in order to still have access to the original error (and stack).
   */
  cause?: Error
  /**
   * The error context.
   *
   * The error message should be constant (for grouping purpose), so `context`
   * is the place to put every variable element of context.
   */
  context?: Jsonable
}

class BaseError extends Error {
  public readonly context?: Jsonable

  constructor(message: string, options: ErrorOptions = {}) {
    const { cause, context } = options

    super(message, { cause })
    this.name = this.constructor.name

    this.context = context
  }
}

export class TechnicalError extends BaseError {}

export class AbortedError extends BaseError {}

export class AssertionError extends BaseError {}

/**
 * This utility exception can be used to get a compile-time error based on whether a value is
 * never or not.
 * Inspired from workaround solutions of https://github.com/microsoft/TypeScript/issues/38881
 * Remove this when/if the issue is closed some day.
 */
export class UnreachableCaseError extends TechnicalError {
  constructor(value: never) {
    super('Unreachable code reached', { context: value })
  }
}

export class NotYetImplementedError extends TechnicalError {
  constructor() {
    super('Not yet implemented')
  }
}

export type Jsonable =
  | string
  | number
  | boolean
  | null
  | undefined
  | readonly Jsonable[]
  | { readonly [key: string]: Jsonable }
  | { toJSON(): Jsonable }

export function checkDefinedAndNotNull<T>(value: T | undefined | null, context: Jsonable = {}): NonNullable<T> {
  if (value == null) {
    throw new TechnicalError('Expected defined and not null value, got null or undefined', { context })
  }
  return value
}
