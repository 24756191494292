import type { MutaGuaranteeGroupType, PolicyGuaranteeVisualPriority } from '../quote-offer-information-dimension.js'

export const mutaGuaranteeProductInformation = {
  MedecinGeneraliste: {
    name: 'Médecin généraliste ou spécialiste',
    shortText: 'Consultation de médecin ou acte de chirurgie, échographie et imagerie',
    fullText: 'Nous couvrons les consultation généraliste ou acte de chirurgie, échographie et imagerie.',
    example: `Consultez un médecin pour un rhume tenace, coûteux sans couverture intégrale car la sécurité sociale ne couvre que 70% des frais.

La mutuelle Orus prend en charge les 30% restants, vous évitant ainsi des frais supplémentaires pour des soins médicaux essentiels.`,
    priority: 'P1',
  },
  Paramedicaux: {
    name: 'Paramédical',
    shortText: 'Infirmiers, kinésithérapeutes, orthophonistes et orthoptistes',
    fullText: 'Nous couvrons les auxiliaires médicaux, Infirmiers, aide-soignants, puériculteurs, rééducation.',
    example: `Consultation chez le kiné pour douleur à l'épaule, coûteuse sans remboursement intégral car la sécurité sociale ne couvre que 70% des frais.

La mutuelle Orus couvre les 30% restants, garantissant des soins complets sans souci financier.`,
    priority: 'P1',
  },
  AnalysesExamsLaboratoires: {
    name: 'Analyses et examens de laboratoires',
    shortText: 'Examens biochimiques, bactériologiques, examens de sang',
    fullText: 'Nous couvrons les examens biochimiques, bactériologiques, examens de sang.',
    example: `Récemment, vous avez fait une analyse sanguine vitale pour contrôler votre cholestérol. Malgré son importance pour votre santé cardiovasculaire, elle peut être coûteuse, avec seulement 60% remboursés par la sécurité sociale.

Votre mutuelle Orus intervient pour vous aider à couvrir les 40% restants.`,
    priority: 'P1',
  },
  Medicaments: {
    name: 'Médicaments',
    shortText: 'Médicaments pris en charge',
    fullText: 'Nous couvrons les médicaments pris en charge par la sécurité sociale.',
    example: `Après avoir consulté votre médecin et obtenu une prescription, il est possible que la sécurité sociale ne couvre pas tous les frais des médicaments, vous laissant une partie à payer à la pharmacie.

La mutuelle Orus intervient pour couvrir cette dépense résiduelle, prenant en charge le montant que vous auriez normalement dû payer.`,
    priority: 'P1',
  },
  Transport: {
    name: 'Transport',
    shortText: 'Transport vers ou depuis votre lieu de soin (hors cures thermales)',
    fullText: 'Nous couvrons les transports soit vers ou depuis votre lieu de soin (Sauf cures thermales).',
    example: `Après votre opération à l'hôpital, les frais de déplacement pour les visites médicales post-opératoires ne sont pas entièrement pris en charge par la sécurité sociale, vous laissant la facture à régler.

La mutuelle Orus intervient pour couvrir ces frais, vous évitant ainsi tout souci financier lié à vos déplacements médicaux et vous permettant de vous concentrer pleinement sur votre rétablissement.`,
    priority: 'P1',
  },
  MedecineNaturelle: {
    name: 'Médecine naturelle',
    shortText:
      "Consultations auprès d'ostéopathes, acupuncteurs, chiropracteurs, réflexologues, diététiciens et podologues.",
    fullText:
      "Forfait annuel pour les consultations d'ostéopathie, acupuncture, chiropraxie, réflexologie, diététique et podologie.",
    example: `Les traitements classiques de votre médecin ne soulagent pas vos douleurs chroniques au dos. Vous optez pour l'ostéopathie, mais la sécurité sociale ne rembourse généralement pas ces consultations. Vous devez donc les payer vous-même.

La mutuelle Orus couvre ces frais pour vous.`,
    priority: 'P1',
  },
  ActesDePrevention: {
    name: 'Actes de prévention',
    shortText: 'Actes préventifs, comme les vaccinations, les dépistages et les bilans du langage oral',
    fullText:
      'Sauf cures thermales actes préventifs, comme les vaccinations, les dépistages et les bilans du langage oral.',
    example: `Sachant l'importance du dépistage de l'hépatite B pour prévenir les maladies du foie, vous décidez de le faire. Mais la sécurité sociale ne couvre pas tous les frais associés.

Ne vous inquiétez pas des coûts, nous prenons en charge ce dépistage pour vous.`,
    priority: 'P1',
  },
  PetitAppareillage: {
    name: 'Petit appareillage',
    shortText:
      'Attelles, chevillières, chaussures thérapeutiques, bas de contention, ceinture de soutien lombaire, etc.',
    fullText:
      'Nous couvons les frais pour des attelles, chevillière, chaussures thérapeutiques, bas de contention, ceinture de soutien lombaire, etc.',
    example: `Vous vous blessez au pied et votre médecin vous recommande une chevillère pour favoriser votre guérison. À la pharmacie, vous constatez que la sécurité sociale ne rembourse qu'une partie des frais, vous laissant une partie à payer de votre poche.

Pour ces petits appareillages, comme les attelles ou les chevillières, nous complétons le remboursement de la sécurité sociale.`,
    priority: 'P1',
  },
  ProthesesEtAppareillages: {
    name: 'Prothèses et appareillages',
    shortText: 'Fauteuils roulants, prothèses et appareillages autres que dentaires et auditifs',
    fullText:
      'Nous couvons les frais pour les fauteuils roulants, prothèses et appareillages autres que dentaires et auditif.',
    example: `À la suite d'un accident, votre médecin vous conseille un fauteuil roulant spécifique pour faciliter votre récupération. Mais lors de l'achat, vous réalisez que votre assurance santé ne couvre qu'une partie du coût, vous laissant avec un fardeau financier inattendu.

La mutuelle Orus prévoit un remboursement supplémentaire pour les dispositifs comme les fauteuils roulants ou les prothèses, allégeant ainsi votre charge financière.`,
    priority: 'P1',
  },
  SoinsDentaires: {
    name: 'Soins dentaires',
    shortText: 'Consultations et soins dentaires, dont inlays, onlays et scellement des sillons',
    fullText: 'Nous couvons les consultations et soins dentaires, dont Inlays, onlays, scellement des sillons.',
    example: `Lors de votre consultation dentaire de routine, bien que la sécurité sociale prenne en charge 60% des frais, il reste encore des frais à votre charge.

La mutuelle Orus intervient pour compléter ce remboursement en prenant en charge les coûts restants de la consultation chez le dentiste.`,
    priority: 'P1',
  },
  Orthodontie: {
    name: 'Orthodontie',
    shortText: 'Pour les moins de 16 ans uniquement',
    fullText: `Pris en charge pour les moins de 16 ans.

(Consulter Soins dentaires non pris en charge pour les autres cas).`,
    example: `Votre enfant de 14 ans doit suivre un traitement orthodontique pour corriger ses problèmes dentaires. Bien que la sécurité sociale prenne partiellement en charge le traitement, une part importante reste à votre charge.

La mutuelle Orus offre une couverture complémentaire spécifique pour le traitement orthodontique de votre enfant.`,
    priority: 'P1',
  },
  ProthesesDentaires: {
    name: 'Prothèses dentaires',
    shortText: 'Prothèses dentaires et couronnes, dont inlay-core.',
    fullText: 'Nous couvons les prothèses dentaires et couronnes, Inlays-core et implantologie pris en charge.',
    example: `Lors de votre consultation dentaire pour la pose d'une prothèse dentaire, comme une couronne, pour préserver une dent dévitalisée, vous constatez que la sécurité sociale ne couvre pas entièrement les frais. Une partie reste donc à votre charge.

Notre garantie dédiée aux prothèses dentaires intervient pour compléter ce remboursement en prenant en charge les coûts restants associés à la pose de la prothèse dentaire.`,
    priority: 'P1',
  },
  DentaireNonPrisEnCharge: {
    name: 'Dentaire non pris en charge',
    shortText:
      'Forfait annuel pour l’orthodontie pour adulte et les implants non pris en charge par la Sécurité sociale',
    fullText: 'Forfait annuel pour les implants non pris en charge, orthodontie pour adulte.',
    example: `Un traitement d'orthodontie est essentiel pour corriger vos problèmes dentaires. Après consultation avec votre orthodontiste, un plan de traitement est recommandé pour réaligner vos dents et améliorer votre santé bucco-dentaire. Cependant, après 16 ans, la Sécurité sociale ne prend pas en charge ces frais, vous laissant une part importante à payer.

Notre couverture complémentaire est prévue pour ces traitements spécifiques, vous offrant ainsi une solution pour couvrir ces dépenses.`,
    priority: 'P1',
  },
  MontureVerresSimples: {
    name: 'Montures et verres simples',
    shortText: 'Monture (jusqu’à 100 €) et 2 verres simples',
    fullText: 'Prise en charge d’une monture d’une limite de 100 € et de 2 verres simples.',
    example: `Vous constatez que votre vue se détériore et que vous avez besoin de lunettes pour corriger votre vision. Cependant, vous savez que les frais d'une nouvelle monture et de verres correcteurs peuvent être élevés. La sécurité sociale ne couvrant pas la totalité de ces frais, vous pourriez avoir un reste à charge important.

La mutuelle Orus intervient pour vous aider à couvrir cette dépense résiduelle, prenant en charge le montant que vous auriez normalement dû payer pour obtenir vos nouvelles lunettes.`,
    priority: 'P1',
  },
  MontureVerresComplexes: {
    name: 'Montures et verres complexes',
    shortText: 'Monture (jusqu’à 100 €) et 2 verres complexes ou très complexes',
    fullText: 'Prise en charge d’une monture d’une limite de 100 € et de 2 verres complexes ou très complexes.',
    example: `Votre vision s'affaiblit et vous avez besoin de nouvelles lunettes, mais vous savez que les coûts peuvent être élevés. La sécurité sociale ne couvrant pas tous les frais, vous risquez d'avoir un reste à charge important.

La mutuelle Orus intervient pour vous aider, prenant en charge le montant que vous auriez normalement dû payer pour vos nouvelles lunettes.`,
    priority: 'P1',
  },
  Lentilles: {
    name: 'Lentilles',
    shortText: 'Lentilles prescrites, qu’elles soient où non prises en charge par la Sécurité sociale',
    fullText: 'Forfait annuel pour lentilles prescrites prises en charge ou non.',
    example: `En tant que porteur de lentilles de contact, vous appréciez leur commodité et leur confort, mais vous savez que les frais d'achat régulier peuvent s'accumuler. Malheureusement, la sécurité sociale ne rembourse parfois qu'une partie de ces coûts, voire pas du tout.

  La mutuelle Orus intervient pour prendre en charge une partie ou la totalité des dépenses liées à l'achat de vos lentilles, en complément ou non du remboursement de la sécurité sociale.`,
    priority: 'P1',
  },
  AppareillageEtProthesesAuditives: {
    name: 'Appareillages et prothèses auditives',
    shortText: 'Appareillages et prothèses auditives prises en charge',
    fullText: 'Nous couvrons les appareillages et prothèses auditives prises en charge.',
    example: `Suite à une perte auditive soudaine, une prothèse auditive est nécessaire pour améliorer votre audition, selon votre professionnel de la santé auditive. Toutefois, lors de l'achat, vous découvrez que votre assurance santé ne couvre que 60% des frais, vous laissant une part importante à payer.

La mutuelle Orus offre une garantie spécifique pour les appareillages et les prothèses auditives, prévoyant un remboursement additionnel pour soulager ce fardeau financier inattendu.`,
    priority: 'P1',
  },
  AidesAuditives: {
    name: 'Aides auditives',
    shortText: 'Forfait annuel appareil auditif (hors 100% santé)',
    fullText: 'Forfait annuel appareil auditif (hors 100%).',
    example: `En raison de problèmes auditifs, vous avez besoin d'une aide auditive recommandée par votre audioprothésiste pour améliorer votre audition au quotidien. Malheureusement, lors de l'achat, vous découvrez que votre assurance santé ne couvre qu'une partie du coût, en raison de la classification de l'appareil comme de classe II, laissant une part significative à votre charge.

Heureusement, votre mutuelle Orus prévoit un forfait annuel pour l'appareil auditif, couvrant une grande partie, voire la totalité des coûts.`,
    priority: 'P1',
  },
  FraisDeSejour: {
    name: 'Frais de séjour',
    shortText: 'En cas d’acte chirurgical ou de séjour en maternité ou unité spéciale',
    fullText:
      'Nous couvrons les frais de séjour dans un établissement conventionné pour un acte chirurgical, en maternité ou dans une unité spéciale.',
    example: `Imaginons que vous ayez eu besoin d'une intervention chirurgicale planifiée et d'un séjour à l'hôpital dans une unité conventionnée. Bien que la sécurité sociale prenne en charge 80% des frais, le coût du séjour demeure significatif.

Votre mutuelle Orus inclut une garantie permettant de couvrir les coûts résiduels non pris en charge par la sécurité sociale.`,
    priority: 'P1',
  },
  HonorairesPraticiens: {
    name: 'Honoraires de praticiens',
    shortText: 'Praticiens adhérents à un dispositif de pratique tarifaire maîtrisée',
    fullText:
      'Nous prenons en charge les coûts de praticiens adhérents à un dispositif de pratiques tarifaires maîtrisées.',
    example: `Supposons que vous ayez dû subir une intervention par praticien en hôpital. Le coût du séjour, malgré la prise en charge de la sécurité sociale à hauteur de 80% demeure significatif.

Votre mutuelle Orus inclut une garantie permettant de couvrir les coûts résiduels non pris en charge par la sécurité sociale.`,
    priority: 'P1',
  },
  HonorairesEtablissements: {
    name: 'Honoraires des établissements non conventionnés',
    shortText: "Acte de chirurgie, acte d'anesthésie, dépassement d'honoraires",
    fullText: "Nous couvrons les actes de chirurgie, acte d'anesthésie, dépassement d'honoraires.",
    example: `Vous avez besoin d'une intervention chirurgicale majeure et choisissez une clinique privée recommandée pour son confort. Malheureusement, cette clinique n'est pas conventionnée par la sécurité sociale, ce qui entraîne un reste à charge important pour couvrir les frais.

Votre mutuelle Orus permet de rembourser une partie de ces frais supplémentaires.`,
    priority: 'P1',
  },
  ChambreParticuliere: {
    name: 'Chambre particulière',
    shortText: 'La chambre individuelle est considérée comme un soin de confort',
    fullText: 'Nous prenons en charge la chambre individuelle est considérée comme un soin de confort.',
    example: `L'hospitalisation peut être une expérience stressante, surtout lorsque vous partagez une chambre avec d'autres patients, ce qui peut entraver votre récupération. Une chambre individuelle pendant votre séjour à l'hôpital améliorerait considérablement votre expérience, mais malheureusement, ce confort supplémentaire n'est pas couvert par la sécurité sociale.

La mutuelle Orus prendra en charge ce coût supplémentaire pour vous, soulageant ainsi votre charge financière.`,
    priority: 'P1',
  },
  SejourAccompagnant: {
    name: 'Séjour accompagnant',
    shortText: 'Prise en charge d’un lit supplémentaire et des repas de l’accompagnant',
    fullText:
      "Nous prenons en charge les frais liés à la présence d'un accompagnant lors de votre séjour à l'hôpital, ce qui inclut la mise à disposition d'un lit supplémentaire ainsi que les repas de l'accompagnant.",
    example: `Imaginez que vous subissiez une intervention chirurgicale et que vous souhaitiez avoir un membre de votre famille ou un proche à vos côtés pendant votre séjour à l'hôpital.
Votre mutuelle Orus vous permet de bénéficier de cette présence en prenant en charge les frais d'un lit supplémentaire pour l'accompagnant, ainsi que ses repas, ce qui vous offre un soutien et un réconfort supplémentaires tout au long de votre hospitalisation.`,
    priority: 'P1',
  },
  ConfortHospitalier: {
    name: 'Confort hospitalier',
    shortText: 'Accès à une télévision, connexion internet et téléphone, maximum 15 jours par an',
    fullText: 'Nous couvrons l’accès à une télévision, connexion internet et téléphone, maximum 15 jours par an.',
    example: `Vous êtes hospitalisé pour une intervention chirurgicale importante. Pendant votre séjour à l'hôpital, vous réalisez à quel point il est important d'avoir accès à des divertissements tels que la télévision et Internet pour rester en contact avec vos proches.

Cependant, l'accès à ces services à l'hôpital peut entraîner des coûts supplémentaires que la sécurité sociale ne couvre pas.

Nous couvrons donc les frais liés à l'utilisation de ces services ce qui vous permet d'améliorer votre séjour à l’hôpital.`,
    priority: 'P1',
  },
  HospitalisationDomicile: {
    name: 'Hospitalisation à domicile',
    shortText: 'Soins médicaux et paramédicaux dans le cadre d’une hospitalisation à domicile (HAD)',
    fullText: 'Nous couvrons vos soins médicaux et paramédicaux importants à votre domicile (HAD).',
    example: `Pendant votre hospitalisation pour une intervention chirurgicale majeure, vous comprenez l'importance des divertissements tels que la télévision et Internet pour rester en contact avec vos proches. Malheureusement, l'accès à ces services à l'hôpital peut entraîner des coûts supplémentaires non couverts par la sécurité sociale.

La mutuelle Orus prend en charge ces frais, vous permettant ainsi d'améliorer votre séjour à l'hôpital en ayant accès à ces services.`,
    priority: 'P1',
  },
  TeleconsultationMedicale: {
    name: 'Téléconsultation médicale',
    shortText:
      'Téléconsultation et téléprescription médicamenteuse, réalisée par un médecin par téléphone ou en ligne disponible 7j/7 et 24h/24.',
    fullText:
      'Téléconsultation et téléprescription médicamenteuse, réalisée par un médecin par téléphone ou en ligne disponible 7j/7 et 24h/24.',
    example: `Lors de vos vacances, vous tombez malade, mais votre médecin habituel ne propose pas de téléconsultations. Heureusement, grâce à la garantie téléconsultation médicale, vous avez accès à un médecin disponible 24h/24 et 7j/7, prêt à vous aider en ligne ou par téléphone.

  Vous pouvez ainsi discuter de vos symptômes et recevoir des conseils médicaux. Si nécessaire, le médecin peut même vous fournir une prescription médicamenteuse écrite pour traiter votre maladie.`,
    priority: 'P1',
  },
  CoachingBienEtre: {
    name: 'Coaching bien-être',
    shortText: 'Séance en ligne avec un coach spécialisé et accès à du contenu en illimité depuis l’espace en ligne',
    fullText:
      'Nous proposons des séances en ligne avec un coach spécialisé et accès à du contenu en illimité depuis l’espace en ligne',
    example: `Vous êtes déterminé à reprendre un mode de vie sain, en intégrant une routine sportive, une alimentation équilibrée et une meilleure qualité de sommeil dans votre quotidien. Vous comprenez l'importance de disposer des ressources adéquates pour atteindre cet objectif.

Cette garantie vous donne accès à une gamme complète de ressources pour vous soutenir dans votre démarche. Vous avez accès à un portail riche en contenus, comprenant des exercices de respiration, des plannings de séances de sport et un bilan d'évaluation santé. De plus, cette garantie vous offre la possibilité de consulter des professionnels spécialisés tels qu'un entraîneur sportif, un diététicien ou un psychologue.

Le coût de ces consultations est pris en charge par votre mutuelle Orus.`,
    priority: 'P1',
  },
  AccompagnementPsychologique: {
    name: 'Coaching bien-être psychologique',
    shortText: 'Séance en ligne avec psychologue partenaire',
    fullText: 'Nous proposons un accès à un portail digital et 4 entretiens à distance avec un psychologue.',
    example: `Vous êtes très occupé, et vous ressentez depuis un certain temps un niveau de stress élevé lié à votre travail et à vos responsabilités familiales.

Vous pouvez retrouver un psychologue en ligne pour vous aider à gérer votre stress et à améliorer votre bien-être émotionnel.`,
    priority: 'P1',
  },
  AideDomicile: {
    name: 'Aide à domicile',
    shortText: '15 services dont auxiliaire de vie, aide-ménagère, garde d’enfants, personnes à charge et des animaux',
    fullText:
      'Un ensemble de 15 services disponibles, dont auxiliaire de vie, aide-ménagère, garde d’enfants, personnes à charge et des animaux.',
    example: `Après votre hospitalisation, vous regagnez votre domicile, mais vous vous trouvez dans l'incapacité de réaliser les tâches quotidiennes en raison de votre immobilisation. Pour faciliter votre rétablissement et soulager votre charge de travail, vous avez la possibilité de faire appel à une gamme variée de services d'aide à domicile.

Vous pouvez bénéficier d'une gamme étendue de près de 15 services. Cela englobe des prestations telles que la livraison de courses, l'aide ménagère, la garde d'enfants à domicile, et bien d'autres.

En plus de vous proposer des prestataires, nous prenons en charge les frais liés à ces services à domicile.`,
    priority: 'P1',
  },
  TransportTaxi: {
    name: 'Transport taxi',
    shortText:
      'Retour et transfert au domicile, transport des enfants dont vers activité extra-scolaires, livraisons des courses.',
    fullText:
      'Nous prenons en charge les frais de retour et transfert au domicile, transport des enfants dont vers activité extra-scolaires, livraisons des courses.',
    example: `Après votre hospitalisation, vous regagnez votre domicile, mais vous vous trouvez dans l'incapacité de réaliser les tâches quotidiennes en raison de votre immobilisation.

Pour faciliter votre rétablissement et soulager votre charge de travail, vous avez la possibilité de faire appel à une gamme variée de services d'aide à domicile.

Cela englobe des prestations telles que la conduite en taxi à l'école ou aux activités extra-scolaires des enfants, le transfert d'un de vos proche à votre domicile, la livraison de courses…`,
    priority: 'P1',
  },
} satisfies Record<
  MutaGuaranteeGroupType,
  Record<'name' | 'shortText' | 'fullText' | 'example', string> | { priority: PolicyGuaranteeVisualPriority }
>
