import styled from '@emotion/styled'
import { TextField as MuiTextField, type InputBaseComponentProps } from '@mui/material'
import type { InputProps } from '@mui/material/Input'
import { memo, type ChangeEvent, type FocusEvent, type InputHTMLAttributes, type KeyboardEvent, type Ref } from 'react'
import { colorTokens } from '../../../foundation/color-tokens.js'
import { shadow } from '../../../foundation/shadow-tokens.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { useScreenVariant, type ScreenVariant } from '../../../hooks/use-screen-variant.js'
import { cssPropsPerScreenVariantPerTextVariant, type TextCssProps } from '../../atoms/text/css-props.js'

export type TextFieldSize = 'small' | 'large'
export type OrusTextFieldVariant = 'mobile' | 'desktop'

export type TextFieldProps = {
  autoFocus?: boolean
  multiline?: boolean
  disabled?: boolean
  placeholder?: string
  fullWidth?: boolean
  value?: string
  className?: string
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: KeyboardEvent) => void
  error?: boolean
  helperText?: string
  inputProps?: InputBaseComponentProps
  InputProps?: Partial<InputProps>
  inputRef?: Ref<HTMLInputElement>
  type?: InputHTMLAttributes<unknown>['type']
  size: TextFieldSize
  variant?: ScreenVariant
  centerContent?: boolean
  ['aria-label']?: string
}

const textFieldDimensionsPerSize: {
  [size in TextFieldSize]: { height: string }
} = {
  small: {
    height: '32px',
  },
  large: {
    height: '48px',
  },
}

export const TextField = memo<TextFieldProps>(function OrusTextField(props) {
  const {
    value,
    inputProps,
    InputProps,
    inputRef,
    type,
    helperText,
    className,
    autoFocus,
    multiline,
    placeholder,
    disabled,
    onBlur,
    onFocus,
    onChange,
    onKeyDown,
    error,
    size,
    variant,
    centerContent = false,
    'aria-label': ariaLabel,
  } = props
  const screenVariant = useScreenVariant()
  const inputTypography = cssPropsPerScreenVariantPerTextVariant['input'][variant ?? screenVariant]
  const helperTextTypography = cssPropsPerScreenVariantPerTextVariant['caption'][variant ?? screenVariant]

  return (
    <StyledTextField
      autoFocus={autoFocus}
      multiline={multiline}
      disabled={disabled}
      placeholder={placeholder}
      fullWidth={true}
      value={value || ''}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      onKeyDown={onKeyDown}
      error={error}
      helperText={helperText}
      className={className}
      InputProps={{
        ...InputProps,
        style: { ...inputTypography },
      }}
      inputProps={{
        ...inputProps,
        'data-hj-allow': '',
        'aria-label': ariaLabel,
      }}
      helperTextTypography={helperTextTypography}
      inputRef={inputRef}
      type={type}
      size={size === 'large' ? 'medium' : 'small'}
      orusSize={size}
      centerContent={centerContent}
    />
  )
})

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (propName) => !['helperTextTypography', 'orusSize', 'centerContent'].includes(propName),
})<{ helperTextTypography: TextCssProps; orusSize: TextFieldSize; centerContent: boolean }>`
  & .MuiInputBase-input {
    box-sizing: border-box;
    text-align: ${({ centerContent }) => (centerContent ? 'center' : 'left')};
    box-shadow: ${shadow.bottom['05']};
    border-radius: ${spacing[30]};
    height: ${({ orusSize }) => textFieldDimensionsPerSize[orusSize].height};
    padding: 0 ${spacing[40]};

    ::placeholder {
      color: ${colorTokens['color-text-base-disable']} !important;
      opacity: 1 !important;
    }
  }

  & .MuiOutlinedInput-root {
    background-color: ${colorTokens['color-bg-base-normal']};

    & fieldset {
      border-color: ${colorTokens['color-stroke-base']};
    }

    &:hover {
      & fieldset {
        border-color: ${colorTokens['color-stroke-base-hover']} !important;
      }
    }

    &.Mui-error {
      & fieldset {
        border-color: ${colorTokens['color-stroke-danger']} !important;
      }
    }

    &.Mui-disabled {
      background-color: ${colorTokens['color-bg-base-disable']};

      & input {
        box-shadow: none !important;
        -webkit-text-fill-color: 'none';
        color: ${colorTokens['color-text-base-disable']};
      }

      & fieldset {
        border-color: ${colorTokens['color-stroke-base-disable']} !important;
      }
    }

    &.Mui-focused {
      & fieldset {
        border-color: ${colorTokens['color-stroke-base-selected']} !important;
      }
    }
  }

  & .MuiFormHelperText-root {
    margin: ${spacing[20]} 0 0 0 !important;
    color: ${colorTokens['color-text-base-basic']};
    ${({ helperTextTypography }) => helperTextTypography}

    &.Mui-error {
      color: ${colorTokens['color-text-danger-secondary']};
    }
  }
`
