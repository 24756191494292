import { memo, useCallback, useMemo, useState, type ComponentProps, type FormEvent, type ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports -- We can rely on MUI for pharaoh implementation details
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Avatar } from '../../components/atoms/avatar/avatar.js'
import type { CompoundIconName } from '../../components/atoms/icon/index.js'
import { Text } from '../../components/atoms/text/text.js'
import { Button, buttonDimensionsPerButtonSize, type ButtonAvatarPlacement } from '../../components/button/index.js'
import { borderRadius } from '../../foundation/border-radius-tokens.js'
import { colorTokens } from '../../foundation/color-tokens.js'
import { shadow } from '../../foundation/shadow-tokens.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { useIsOwerflowing } from '../../hooks/use-is-overflowing.js'
import { desktopMediaQuery, useScreenType } from '../../hooks/use-screen-type.js'
import { shouldForwardProp } from '../../styled.js'

export type DialogSize = 'small' | 'medium' | 'large' | 'xlarge'

export type DialogStyle =
  /**
   * The default style, with a neutral look and feel
   */
  | 'base'
  /**
   * A style that indicates the success of an operation, or good news in general
   */
  | 'success'
  /**
   * A style that indicates a dangerous situation
   */
  | 'danger'

export type DialogProps = {
  /**
   * The main content of the dialog
   */
  children: ReactNode
  /**
   * A callback called to close the dialog
   */
  onClose?: () => void
  size: DialogSize
  /**
   * An optinal icon displayed in the header of the dialog
   */
  icon?: CompoundIconName

  /**
   * An optinal avatar displayed in the header of the dialog
   */
  avatar?: ReactNode
  /**
   * An optional title displayed in the header of the dialog
   */
  title?: string
  /**
   * An optional footer text displayed at the bottom of the dialog
   */
  footerText?: string
  /** An optional group of buttons displayed in place of the close button */
  topRightButtonGroup?: ReactNode
  /**
   * An optional semantic style applied to the dialog. Sets default values for some color and icons
   */
  style?: DialogStyle
  /** Whether the overlay should be blurred */
  blurOverlay?: boolean
  /** Customize the form's id */
  formId?: string
  primaryActionLabel?: string
  primaryActionDisabled?: boolean
  primaryActionAvatar?: React.ReactNode
  primaryActionAvatarPosition?: ButtonAvatarPlacement
  onPrimaryAction?: () => void
  secondaryActionLabel?: string
  secondaryActionDisabled?: boolean
  onSecondaryAction?: () => void
  isBackoffice?: boolean
}

const desktopDialogSizes: Record<DialogSize, string> = {
  small: '384px',
  medium: '576px',
  large: '768px',
  xlarge: '1464px',
}

/**
 * The buttons are in their own wrapper, because the ordering between buttons and optional text
 * is not the same between mobile and desktop, no matter which flex direction we use.
 */
const DialogFooterButtons = styled.div`
  flex: 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing['40']};
  margin-left: 0 !important; /* workaround an unwanted extra margin added by MuiDialogActions */
  flex-direction: column-reverse;
  ${desktopMediaQuery} {
    flex-direction: row;
  }
`

const FooterButton = memo<Omit<ComponentProps<typeof Button>, 'size' | 'fullWidth'> & { isBackoffice?: boolean }>(
  function FooterButton(props) {
    const screenType = useScreenType()
    return (
      <Button
        {...props}
        size={screenType === 'mobile' ? 'large' : props.isBackoffice ? 'small' : 'medium'}
        fullWidth={screenType === 'mobile'}
      />
    )
  },
)

const styleIcons: Record<DialogStyle, CompoundIconName | undefined> = {
  base: undefined,
  success: 'circle-check-regular',
  danger: 'diamond-exclamation-regular',
}

const styleColors: Record<DialogStyle, string | undefined> = {
  base: undefined,
  success: colorTokens['color-bg-success-primary'],
  danger: colorTokens['color-bg-danger-primary'],
}

const DesktopEmptyFooterFiller = styled.div`
  height: ${buttonDimensionsPerButtonSize.medium.height};
`

export const Dialog = memo<DialogProps>(function Dialog(props) {
  const [formId] = useState(props.formId ?? Math.random().toString())
  const screenType = useScreenType()
  const { isOverflowing, ref: contentContainerRef } = useIsOwerflowing()

  const { onPrimaryAction, isBackoffice } = props
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      onPrimaryAction?.()
      event.stopPropagation()
    },
    [onPrimaryAction],
  )

  const { style = 'base' } = props

  const icon = props.icon ?? (style ? styleIcons[style] : undefined)

  const setupAvatar = useMemo(
    () =>
      props.avatar || icon ? <>{props.avatar || <Avatar icon={icon} size="50" color={styleColors[style]} />}</> : <></>,
    [props.avatar, icon, style],
  )

  return (
    <StyledMuiDialog
      open
      $blurOverlay={props.blurOverlay}
      onClose={props.onClose ?? (() => {})}
      fullScreen={screenType === 'mobile'}
      transitionDuration={
        screenType === 'mobile'
          ? {
              appear: 0,
              exit: 0,
            }
          : undefined
      }
      scroll="paper"
      size={props.size}
      $isOverflowing={isOverflowing}
    >
      <DialogTitle>
        {setupAvatar}
        {props.title ? (
          <Text
            css={css`
              flex-grow: 1;
            `}
            variant="subtitle1"
          >
            {props.title}
          </Text>
        ) : null}
        <div
          css={css`
            margin-left: auto;
          `}
        >
          {props.topRightButtonGroup
            ? props.topRightButtonGroup
            : !!props.onClose && (
                <Button variant="secondary" icon="xmark-large-regular" size="small" onClick={props.onClose} />
              )}
        </div>
      </DialogTitle>

      <MuiDialogContent ref={contentContainerRef}>
        <FormContainer id={formId} onSubmit={handleSubmit} $isOverflowing={isOverflowing}>
          {props.children}
        </FormContainer>
      </MuiDialogContent>

      <DialogActions>
        {props.footerText ? (
          <Text
            css={css`
              flex: 1 1;
            `}
            variant="body2"
          >
            {props.footerText}
          </Text>
        ) : null}

        <DialogFooterButtons>
          {props.secondaryActionLabel ? (
            <FooterButton
              isBackoffice={isBackoffice}
              variant="secondary"
              disabled={props.secondaryActionDisabled}
              onClick={props.onSecondaryAction}
              dataTestId="dialog-secondary-button"
            >
              {props.secondaryActionLabel}
            </FooterButton>
          ) : null}
          {props.primaryActionLabel ? (
            <FooterButton
              avatar={props.primaryActionAvatar}
              avatarPosition={props.primaryActionAvatarPosition}
              isBackoffice={isBackoffice}
              form={formId}
              type="submit"
              variant="primary"
              disabled={props.primaryActionDisabled}
              style={style}
            >
              {props.primaryActionLabel}
            </FooterButton>
          ) : null}
        </DialogFooterButtons>

        {!props.primaryActionLabel && !props.secondaryActionLabel && screenType === 'desktop' ? (
          <DesktopEmptyFooterFiller />
        ) : null}
      </DialogActions>
    </StyledMuiDialog>
  )
})

const StyledMuiDialog = styled(MuiDialog, { shouldForwardProp })<{
  size: DialogSize
  $blurOverlay?: boolean
  $isOverflowing: boolean
}>`
  & .MuiDialog-container {
    ${({ $blurOverlay = false }) =>
      $blurOverlay
        ? css`
            backdrop-filter: blur(12px);
          `
        : ``}

    ${({ size, $isOverflowing }) => css`
      & .MuiPaper-root {
        width: 100%;
        box-shadow: ${shadow.bottom[30]};
        border-radius: ${borderRadius[30]};
        border: 1px solid ${colorTokens['color-stroke-base']};
        ${desktopMediaQuery} {
          ${size === 'xlarge'
            ? css`
                min-width: calc(100vw - 2 * ${spacing['60']});
                min-height: calc(100vh - 2 * ${spacing['60']});
              `
            : css`
                max-width: ${desktopDialogSizes[size]};
              `}
        }
      }

      & .MuiDialogContent-root {
        display: flex;
        height: 100%;
        padding: 0 ${spacing['60']};
        border-top: ${$isOverflowing ? `1px solid ${colorTokens['color-stroke-base']}` : 'none'};
        border-bottom: ${$isOverflowing ? `1px solid ${colorTokens['color-stroke-base']}` : 'none'};
      }
    `}
  }
`

const FormContainer = styled('form', { shouldForwardProp })<{ $isOverflowing: boolean }>`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  padding-top: ${({ $isOverflowing }) => ($isOverflowing ? spacing['60'] : '0')};
  padding-bottom: ${spacing['60']};
`

const DialogTitle = styled(MuiDialogTitle)`
  display: flex;
  padding: ${spacing['60']};
  align-items: center;
  gap: ${spacing['40']};
`

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: ${spacing['60']};
  justify-content: flex-end;
  gap: ${spacing['40']};
  align-items: stretch;
  flex-flow: column wrap;
  ${desktopMediaQuery} {
    align-items: center;
    flex-flow: row wrap;
  }
`
