import type { OperatingZone } from '@orus.eu/operating-zone'
import type { HelpContactDetails } from '@orus.eu/pharaoh/src/components/features/quote-v3/help-block'

export const helpContactDetails: Record<OperatingZone, HelpContactDetails> = {
  fr: {
    email: 'hello@orus.eu',
    phone: '+33 1 89 71 63 04',
    whatsappLink: 'https://wa.me/message/NVLHTZVDLQHHO1',
  },
  es: {
    email: 'hello@orus.eu',
    phone: '+34 518 880 192',
    whatsappLink: 'https://wa.me/message/FKSIIAXR4R4KD1',
  },
}
