import styled from '@emotion/styled'
import { memo, type ReactNode } from 'react'

import type { Color } from '../../colors'
import { Text, TextContainer } from '../../components'
import { colorTokens, spacing } from '../../foundation'
import { useSkin } from '../../skin'

export type CardProps = {
  children: ReactNode
  title: string
  subtitle: string
  avatar: React.ReactNode
  backgroundColor?: Color
  withBorder?: boolean
  fullwidth?: boolean
  subtitleColor?: Color
}
export const Card = memo<CardProps>(function Card(props: CardProps) {
  const {
    children,
    title,
    subtitle,
    avatar,
    backgroundColor,
    subtitleColor,
    withBorder = false,
    fullwidth = false,
  } = props
  const skin = useSkin()

  return (
    <CardContainer backgroundColor={backgroundColor} withBorder={withBorder} fullwidth={fullwidth}>
      {avatar}
      <CardBody color={skin.helpBlockTextColor}>
        <Text variant="subtitle1">{title}</Text>
        <Text variant="body2" color={subtitleColor}>
          {subtitle}
        </Text>
      </CardBody>
      <CardFooter>{children}</CardFooter>
    </CardContainer>
  )
})

const CardContainer = styled.div<{ backgroundColor?: Color; withBorder: boolean; fullwidth: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${spacing['70']};
  border-radius: ${spacing['40']};
  border: ${(props) =>
    `var(--border-stroke-20, 1px) solid ${props.withBorder ? colorTokens['color-stroke-base'] : 'transparent'}`};
  background: ${(props) => props.backgroundColor ?? colorTokens['color-bg-base-normal']};
  width: ${(props) => (props.fullwidth ? '100%' : '320px')};
`

const CardBody = styled(TextContainer)`
  margin-top: ${spacing['40']};
  margin-bottom: ${spacing['90']};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${spacing['30']};
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['40']};
`
